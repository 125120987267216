import React from "react";
import { List, Container } from "./styles";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid pink",
  }),
};

const DropDown = ({ ...rest }) => {
  return (
    <Container>
      <List
        {...rest}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          height: 38,
          padding: '3px 0px',
          borderRadius: 0,
          border: "#dee2e6",
          colors: {
            ...theme.colors,
            primary25: "#FcF1F0 !important",
            primary: "#FF7375  !important",
          },
        })}
        maxMenuHeight={220}
        isClearable={true}
        isSearchable={true}
      />
    </Container>
  );
};

export default DropDown;

