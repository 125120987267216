import styled from "styled-components";

export const Container = styled.div`
  /* top: 70px; */
  /* position: absolute;
  height: 450px;
  width: 300px;
  background: #fff;
  z-index: 9998;
  box-shadow: 1px 5px 6px #666;
  right: 10px;
  border-radius: 10px; */
  
  header {
    height: 50px;
    background-color: ${(props) => props.color };
    /* border-radius: 10px 10px 0 0 ; */
    display: grid;
    grid-template-columns: 10% 80% 10%;
    padding: 0 0 0 15px;
    align-content: center;
    
    img, svg {
      width: 21px;
      height: 21px;
      color: #fff;
    }
    
    p {
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
    }
  }
 
  nav {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 310px;
    
      
    ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      /* border-radius: 5px; */
      background: #221C46;
    }
  }
 
  h1 {
    padding: 0 15px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: #545454;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  
  main {
    border-bottom: 1px solid #d6d6d6;
    
    img {
      width: 22px;
      height: 22px;
      margin-left: 10px;
    }
    
    svg {
      width: 22px;
      height: 22px;
      /* justify-self: center; */
      color: #221C46;
      margin-left: 10px;
    }
    
    p {
      color: #221C46;
    }
    
    label {
      justify-self: right;
    }
  }
  
  button {
    float: right;
    margin: 0 10px;
    /* background-color: #931a28;
    color: #fff;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    text-align: center; */
  }
`;


export const Checkbox = styled.div`
  margin: 10px 0;

  div {
    display: grid;
    grid-template-columns: ${(props) => (props.grid === "evitar" && "37% 31% 31%")};
    grid-template-columns: ${(props) => (props.grid === "filtros" && "25% 30% 22% 23%")};
  }
  
  main {
    display: grid;
    /* grid-template-columns: 20% 55% 22%; */
    grid-template-columns: ${(props) => (props.route ? "10% 43% 38% 9%" : "20% 55% 22%" )};
    align-items: ${(props) => (props.route ? 'center' : "" )};
    margin: 8px 0;
    border-bottom: 1px solid #d6d6d6;
    padding-bottom: 8px;
    button {
      margin: 0;
    }
  }
  
  

  label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 13px;
  color: #545454;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0!important;
  width: 0!important;
}

span {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #221C46;   
}

label:hover input ~ span {
  background-color: #f6f6f6;
}

label input:checked ~ span {
  background-color: #FF7375;
}

span:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ span:after {
  display: block;
}

label span:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

export const Modal = styled.div`

.flexible-modal {
    /* border-radius: 10px; */
    z-index: 1000;
    right: 60px;
    height: 415px!important;
    width: ${(props) => (props.route ? "440px!important" : "300px!important" )};
    
    
    @media (max-width: 768px) {
      top:  130px!important;
      width: 300px!important;
    }
  }
  
  .flexible-modal-drag-area {
    background: transparent; 
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%!important;
    cursor: move;
    right: auto!important;
  }
  
  .flexible-modal-drag-area-left{
      position:absolute;
      left:0;
      bottom:0;
      cursor:move;
    }

    .flexible-modal-drag-area-right{
      height: 50px;
      position:absolute;
      right:0;
      bottom:0;
      cursor:move;
    }

    .flexible-modal-drag-area-bottom{
      height: 50px;
      position:absolute;
      right:0;
      bottom:0;
      cursor:move;
      width: 50%!important;
      right: auto;
    }
`;