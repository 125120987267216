import React, { useState, useCallback, useContext, useEffect, memo } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import LanguageContext from "./../../context/language";
import { FiChevronDown } from 'react-icons/fi'

const DropdownLanguage = () => {
const { language, handleSetLanguage } = useContext(LanguageContext);

const [dropDownOpen, setDropDownOpen] = useState(false);
const [flag, setFlag] = useState(language);

useEffect(() => {
  if (language === "en") {
    setFlag("fi fi-us");
  } else if (language === "pt_BR") {
    setFlag("fi fi-br");
  } else if (language === "es") {
    setFlag("fi fi-es");
  } else {
    setFlag("pt_BR");
  }
  
//   handleSetLanguage("pt_BR")
}, [language]);

const toggle = useCallback(() => {
  setDropDownOpen(!dropDownOpen);
}, [dropDownOpen]);
  
return (
    <Dropdown
    isOpen={dropDownOpen}
    toggle={toggle}
    className="dropdown"
    // tag="li"
  >
    <DropdownToggle
      className="dropdown-toggle"
      tag="a"
      style={{ cursor: "pointer" }}
    >
      <span className={`flag-icon ${flag} m-r-5`} title="us"></span>
      {/* <span className="name d-none d-sm-inline"></span> */}
      <FiChevronDown style={{  float: 'right', marginTop: 3, marginLeft: 3}}/>
    </DropdownToggle>
    <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
      <DropdownItem onClick={() => handleSetLanguage("en")}>
        <span className="flag-icon fi fi-us m-r-5" title="us" style={{marginRight: 10}}></span>
        <FormattedMessage id="ingles" />
      </DropdownItem>
      <DropdownItem onClick={() => handleSetLanguage("pt_BR")}>
        <span className="flag-icon fi fi-br m-r-5" title="br" style={{marginRight: 10}}></span>
        <FormattedMessage id="portugues" />
      </DropdownItem>
      <DropdownItem onClick={() => handleSetLanguage("es")}>
        <span className="flag-icon fi fi-es m-r-5" title="es" style={{marginRight: 10}}></span>
        <FormattedMessage id="espanhol" />
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
);
};

export default memo(DropdownLanguage);
