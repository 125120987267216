import React, { useEffect, useState, useCallback }  from "react";
import { useGlobal, setGlobal } from 'reactn';
import { FaTimes } from 'react-icons/fa'
import { AiFillStar } from 'react-icons/ai'
import iconBomba from "./../../assets/bomba.png";

import api from "./../../services/api";
import { format } from "date-fns";

import { FormattedMessage } from "react-intl";

import { Carousel } from 'react-responsive-carousel';

import { Content, ModalCarousel } from "./styles";
import { searchAddress } from "../../services/address";
import { Button } from '@dilicorp/ui';


setGlobal({});

export const ModalInfo = ({id, center}) => {
  
  const [listInfos, setListInfos] = useGlobal('listInfos');
  const [listEstablishments, setListEstablishments] = useGlobal('listEstablishment');
  
  const [modalSelected, setModalSelected] = useState("dados");
  
  const [openModalImage, setOpenModalImage] = useGlobal('openModalImage');
  const [indexImage, setIndexImage] = useGlobal('indexImage');
  
  const [pontoPartida, setPontoPartida] = useGlobal("pontoPartida");
  const [endereços, setEndereços] = useGlobal("endereços");
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  const [menuOpen, setMenuOpen] = useGlobal('menuOpen');
  const [openModal, setOpenModal] = useGlobal('openModal');
  const [modalFix, setModalFix] = useGlobal('modalFix');
    
  function mtel(v){
    v=v.replace(/\D/g,"");             
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); 
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");
    return v;
  }  
  
  const loadInfos = useCallback(async () => {
    try {
      
      const { data: response } = await api.get(
        `merchants?columns=all&id=${id}&products=true&services=true&favorites=true`,
      );
      
      const formattedInfos = {
        id: response.data[0].id,
        favorite: response.data[0].user[0] ? true : false,
        icon: response.data[0].brand_icon,
        dados: {
          nome: response.data[0].trade_name,
          comercio: response.data[0].brand_name,
          telefone: mtel(response.data[0].phone),
          endereço: response.data[0].street + ", " + response.data[0].number,
          bairro: "",
          estado: response.data[0].city + " - " + response.data[0].state
        },
        especialidades: response.data[0]?.services,
        fotos: [
          // {foto: response.data[0]?.photos},
          {foto: "https://www.shell.com.br/motoristas/sobre-os-postos-shell/_jcr_content/pagePromo/image.img.960.jpeg/1513372942486/car-parked-at-shell-gas-station.jpeg"},
          {foto: "https://midias.jornalcruzeiro.com.br/wp-content/uploads/2019/06/980-20.jpg"},
          {foto: "https://www.shell.com.br/motoristas/sobre-os-postos-shell/_jcr_content/pagePromo/image.img.960.jpeg/1513372942486/car-parked-at-shell-gas-station.jpeg"},
          {foto: "https://midias.jornalcruzeiro.com.br/wp-content/uploads/2019/06/980-20.jpg"},
        ],
        preços: response.data[0]?.products
      }
      
      setListInfos(formattedInfos)
      
    } catch (error) {
      console.log(error)
    }
  }, [id, setListInfos]);
  
  useEffect(() => {
    loadInfos();
    setEndereços(endereços);
    setPontoPartida(pontoPartida);
    setCollapsed(collapsed);
    setMenuOpen(menuOpen);
    setIndexImage(indexImage);
  }, [setListInfos,  collapsed, endereços, indexImage, loadInfos, menuOpen, pontoPartida, setCollapsed, setEndereços, setIndexImage, setMenuOpen,  setPontoPartida]);
  
  const newRoute = useCallback(async () => {
    const response = await searchAddress(center);
    setPontoPartida(response.results[0].formatted_address);
    
    setEndereços([{
      local: listInfos?.dados?.endereço + " - " + listInfos?.dados?.estado,
    }])
    
    setCollapsed(false);
    setOpenModal(!openModal);
    setMenuOpen('rota');
  }, [listInfos, center, openModal, setCollapsed, setEndereços, setMenuOpen, setOpenModal,  setPontoPartida]);
  
  const handleFixLocation = useCallback(async () => {
     setModalFix(!modalFix);
     setCollapsed(true);
  }, [modalFix, setCollapsed, setModalFix]);
  
  const handleFavorite = useCallback(async () => {
    try {
      await api.post(`/merchants/favorite`, {
        merchant_id: listInfos?.id,
      });
      
      try {
      
        const { data: response } = await api.get(
          `merchants?id=${listInfos?.id}&favorites=true`,
        );
        
       listInfos.favorite = response.data[0].user[0] ? true : false;
       
       listEstablishments.forEach((establishment) => {
        if (establishment.id === listInfos?.id) {
          establishment.favorite = response.data[0].user[0] ? true : false
        }
       });
       
       setListInfos(listInfos);
       setListEstablishments(listEstablishments);
       
      } catch (error) {
        console.log(error)
      }
      
    } catch (error) {
      console.log(error)
    }
  }, [listInfos, listEstablishments, setListEstablishments, setListInfos]);
  
  return (
    <Content>
      <header><FormattedMessage id="dadosEstabelecimento" /></header>
        <section>
          <nav>
            <h3 className={`${modalSelected === 'dados' ? "selected" : ""}`} onClick={() => setModalSelected("dados")}>
            <FormattedMessage id="dados" />
            </h3>
            <h3 className={`${modalSelected === 'especialidades' ? "selected" : ""}`} onClick={() => setModalSelected("especialidades")}>
            <FormattedMessage id="serviços" />
            </h3>
            <h3 className={`${modalSelected === 'fotos' ? "selected" : ""}`} onClick={() => setModalSelected("fotos")}>
            <FormattedMessage id="fotos" />
            </h3>
            <h3 className={`${modalSelected === 'preços' ? "selected" : ""}`} onClick={() => setModalSelected("preços")}>
            <FormattedMessage id="preços" />
            </h3>
          </nav>
          
     
          {modalSelected === "dados" && ( 
              <>
               <div>
                 <img src={listInfos?.icon} alt={"icon"} />
                 {listInfos?.id && (
                  <AiFillStar 
                    className="star" 
                    onClick={handleFavorite}
                    color={listInfos?.favorite ? '#f1e22a' : '#bebebe'}
                  />
                 )}
                 <span>
                   <h5>{listInfos?.dados?.nome}</h5>
                   <p>{listInfos?.dados?.comercio}</p>
                   {/* <p>{listInfos?.id}</p>s */}
                 </span>
                 
               </div>
               
               <p><FormattedMessage id="telefone" />: {listInfos?.dados?.telefone}</p>
               <p>{listInfos?.dados?.endereço}</p>
               <p>{listInfos?.dados?.bairro}</p>
               <p>{listInfos?.dados?.estado}</p>
               <br/>            
               
               <Button onClick={() => newRoute()}>
                 <p><FormattedMessage id="novaRota" /></p>
               </Button>
             </>
          )}
          
          {modalSelected === "especialidades" && (
            listInfos?.especialidades.map((especialidade, index) => (
              <div key={index}>
                <p> - {especialidade?.name}</p>
              </div>
            ))
          )}
          
          {modalSelected === "fotos" && (
             <main className="container-image">
               {listInfos?.fotos.map((foto, index) => (
                  <img 
                    src={foto.foto} 
                    alt={'image'+index}
                    key={index} 
                    onClick={() => {
                      setOpenModalImage(!openModalImage);
                      setIndexImage(index);
                    }} 
                  />
              ))}
            </main>
          )}
          
          {modalSelected === "preços" && (
            <>
              <main className="container-preço">
                <h6><FormattedMessage id="combustivel" /></h6>
                <h6><FormattedMessage id="atualização" /></h6>
                <h6><FormattedMessage id="preços" /> (<FormattedMessage id="moeda" />)</h6>
              </main>
               {listInfos?.preços.map((preço, index) => (
                preço?.price !== "0.000" && (
                  <main className="container-preço" key={index}>
                    <div>
                      {/* <RiGasStationFill /> */}
                      <img src={iconBomba} alt="iconBomba" />
                      {preço.name}
                    </div>
                    <div>
                      {preço?.date && format(new Date(preço?.date), 'dd/MM/yy')}
                      {preço?.pivot?.date && format(new Date(preço?.pivot?.date), 'dd/MM/yy')}
                    </div>
                    <div style={{justifySelf: 'right'}}>
                      ${preço?.price || preço?.pivot?.price}
                    </div>
                  </main>
                )
              ))}
              <br/>
              <h1 className={'text-center'}><FormattedMessage id="valoresReferencia" /></h1>
            </>
          )}
   
        </section>  
      </Content>
  );
};

export const CarouselImage = () => {
  const [listInfos] = useGlobal('listInfos');
  const [indexImage] = useGlobal('indexImage');
  const [openModalImage, setOpenModalImage] = useGlobal('openModalImage');
  
  return (
    <>
      {openModalImage && (
        <ModalCarousel>
          <FaTimes onClick={() => setOpenModalImage(false)}/>
          <Carousel selectedItem={indexImage}>
            {listInfos?.fotos.map((foto, index) => (
              <div key={index}>
               <img src={foto.foto} alt={index}/>
             </div>
            ))}
          </Carousel>
        </ModalCarousel>
      )}
    </>
  )
}

