import { LocalStorageAdapter } from "./local-storage-adapter";

export const makeLocalStorageAdapter = () =>
  new LocalStorageAdapter();

export const setCurrentTokenAdapter = (account) => {
  makeLocalStorageAdapter().set(
    "@wizeoTradeRede:token",
    account["@wizeoTradeRede:token"]
  );
};

export const deleteCurrentTokenAdapter = () => {
  makeLocalStorageAdapter().set("@wizeoTradeRede:token", null);
};

export const getCurrentTokenAdapter = () => {
  return makeLocalStorageAdapter().get("@wizeoTradeRede:token");
};
