import React, { forwardRef } from "react";
import { Input } from '@dilicorp/ui';

const InputDefault = forwardRef((props, ref) => {
  const { label,
    placeholder,
    readOnly,
    value,
    type,
    onChange,
    style,
    ...rest} = props

  return (
    <Input
      ref={ref}
      {...props}
      {...rest}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      type={type}
      style={style}
    />
  );
});

export default InputDefault;
