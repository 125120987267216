import api from "./api";
import apiV2 from "./api-v2"

const merchantApi = {
    fetch: async (page, limit) => {
        let merchants = [];

        try {
            const { data } = await api.get(
                `merchants?columns=brand_icon,brand_name,activity,preferential&favorites=true&limit=${limit}&page=${page}`,
            );

            merchants = data.data;
        } catch (error) {
            console.error(error);
        }

        return merchants;
    },

    fetchAll: async (limit, params, callback) => {
        let merchants = [];
        params = params ? `${params}` : "";

        try {
            let page = 1;
            let keepFetching = true;

            while (keepFetching) {
                const { data } = await api.get(
                    `merchants?columns=all&favorites=true&limit=${limit}&page=${page}${params}`,
                );

                if (data.meta.last_page === page) {
                    keepFetching = false;
                }

                merchants = [...merchants, ...data.data];
                callback(merchants);
                page++;
            }
        } catch (error) {
            console.error(error);
        }

        return merchants;
    },

    fetchLocations: async (products, body) => {
        try {
            const { data } = await apiV2.post(`/api/v2/m/merchant/locations?productId=${products}`, body)
            return data
        } catch (error) {
            console.error(error);
        }
    }
}

export const MerchantAPI = merchantApi;
