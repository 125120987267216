/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useContext } from "react";
import { Spinner } from "@dilicorp/ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteCurrentTokenAdapter,
} from "../../adapters/current-token-adapter";
import AuthContext from '../../context/auth';
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

export default function Code() {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { signInWToken } = useContext(AuthContext);

  const handleLogin = useCallback(async (token) => {
    try {
      const login = await signInWToken(token);

      if (login) {
        navigate("/", { replace: true });
      }
    }catch (error) {
      toast.error(<FormattedMessage id="erroCredenciais" />);
      localStorage.clear()
      navigate("/login", { replace: true })
    }
  }, [])

  useEffect(() => {
    const disconnect = search.has("disconnect");
    
    if (disconnect) {
      deleteCurrentTokenAdapter();
      navigate("/login", { replace: true });
      
      return;
    }

    const authorized = search.get("authorized")
    if(authorized && authorized === "0"){
      toast.error(<FormattedMessage id="erroCredenciais" />);
      navigate("/login", { replace: true });

      return;
    }

    const hasAccessToken = search.has("access_token");


    if (hasAccessToken) {
      const accessToken = search.get("access_token") || "";
      handleLogin(accessToken)
    }
  }, [search]);

  return <Spinner />;
}
