import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100vh;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff5c;
  z-index: 999;

  svg {
    height: 50px;
    width: 50px;
    animation: ${rotate} 2s linear infinite;
    color: #931a28;
  }
`;
