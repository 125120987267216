import React from "react";

import { Container } from "./styles";
import { Spinner } from '@dilicorp/ui';


const Content = ({ children, style }) => {
  return (
    <Container style={style}>
      <Spinner/>
      {children}
    </Container> 
  );
};

export default Content;
