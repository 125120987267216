import React, { createContext, useCallback, useEffect, useState } from "react";
import api from "./../services/api";
import axios from "axios";
import Spinner from "./../components/Spinner";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [signed, setSigned] = useState(false)

  useEffect(() => {
    const loadUserToken = localStorage.getItem("@wizeoTradeRede:token"); 
    
    if (loadUserToken) {
      const token = JSON.parse(loadUserToken);
      
      async function getUser() {
        try {
          const { data: response } = await api.get("/users", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          const infoUser = {
            name: response.data.name,
          }
          setUser(infoUser);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          localStorage.removeItem("@wizeoTradeRede:token");
        }
      }
      getUser();
      
    } else {
    setLoading(false);
      
    }
  }, []);
  
  const signOut = useCallback(() => {
    const loadUserToken = localStorage.getItem("@wizeoTradeRede:token");
    const token = JSON.parse(loadUserToken);
    api.post("/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    localStorage.removeItem("@wizeoTradeRede:token");
    localStorage.removeItem("@wizeoTradeRede:signed");

    setUser(null);
    setSigned(false);
  }, []);

  const signInWToken = useCallback(
    async (token) => {
      const data = {
        access_token: token
      };

      const login = await api
        .post("/login", data, { cancelToken: source.token })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.error(
              "Erro ao tentar conectar no Pluxee Roteirizador, Verifique sua conexão e tente novamente."
            );
          }
          
          return error.response.data;
        });

      if (!!login.data) {
        setLoading(true);
        //Usuário Válido
        localStorage.setItem(
          "@wizeoTradeRede:token",
          JSON.stringify(login.data.data.token)
        );
        localStorage.setItem(
          "@wizeoTradeRede:signed",
          true
        );
        api.defaults.headers.common["Authorization"] = "Bearer " + login.data.data.token;

        const { data: response } = await api.get("/users", {
          headers: {
            Authorization: `Bearer ${login.data.data.token}`,
          },
        });
        
        const infoUser = {
          name: response.data.name,
        };
        
        setUser(infoUser);
        setLoading(false);
        setSigned(true)
      } else {
        setLoading(false);

        return login;
      }

      return () => {
        source.cancel();
      };
    },
    [source]
  );

  const signIn = useCallback(
    async (email, password) => {
      const data = {
        email,
        password,
      };


      const login = await api
        .post("/login", data, { cancelToken: source.token })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.error(
              "Erro ao tentar conectar no Pluxee Roteirizador, Verifique sua conexão e tente novamente."
            );
          }
          
          return error.response.data;
        });

      if (!!login.data) {
        setLoading(true);
        //Usuário Válido
        localStorage.setItem(
          "@wizeoTradeRede:token",
          JSON.stringify(login.data.data.token)
        );
        
        api.defaults.headers.common["Authorization"] = "Bearer " + login.data.data.token;

        const { data: response } = await api.get("/users", {
          headers: {
            Authorization: `Bearer ${login.data.data.token}`,
          },
        });
        
        const infoUser = {
          name: response.data.name,
        };
        
        setUser(infoUser);
        setLoading(false);
      } else {
        setLoading(false);

        return login;
      }

      return () => {
        source.cancel();
      };
    },
    [source]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        loading,
        user,
        signIn,
        signOut,
        version: "v1.2.3",
        signInWToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
