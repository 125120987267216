import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import BackgroundImage from "./../../assets/background-login.png";
import Logo from "./../../assets/pluxee-new.png";
import AuthContext from "./../../context/auth";
import { useNavigate } from "react-router-dom";

import {
  FormBuilder,
  PluxeeLogoInverted
} from '@dilicorp/ui'


import {
  Container,
  SignContainer,
  Background,
  TagVersion,
  Footer
} from "./styles";

const SignIn = ({ intl }) => {
  const countries = [
    {
      label: 'Brasil',
      value: 1
    },
    {
      label: 'Colômbia',
      value: 2
    },
    {
      label: 'México',
      value: 3
    }
  ]

  const navigate = useNavigate();
  const [country, setCountry] = useState(countries[0])
  
  const { version } = useContext(AuthContext);

  useEffect(() => {
    const loadUserToken = localStorage.getItem("@wizeoTradeRede:token");
    
    if (loadUserToken) {
      const token = JSON.parse(loadUserToken);
      navigate(`/code?authorized=1&access_token=${token}`, { replace: true });
    }
  }, [navigate]);

  const handleConnect = values => {
    const country = JSON.parse(values.country)
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/oidc/auth?userType=customer&country=${country.value}&redirectUri=${process.env.REACT_APP_URL}/code`
  }
  const logoURL = 'https://institucional.fuelandfleet.com/plx-logo.svg'

  return (
    <>
      
      <Container>
        <SignContainer>
        <img src={Logo} alt="Pluxee" />
          <>
            <div className="image mb-5">
              <img src={logoURL} width="172" height="49" alt="Pluxee Logo"/>
            </div>
            <FormBuilder
              className="form-pluxee-conect"
              data={[
                {
                  items: [
                    {
                      name: 'country',
                      component: 'select',
                      title: 'Selecione o País',
                      options: countries,
                      validations: [],
                      value: country,
                      onChange: async ({ currentTarget: { value } }) => {
                        setCountry(value)
                      }
                    },
                    {
                      name: 'submit',
                      component: 'button',
                      type: 'submit',
                      label: 'Entrar',
                      labelLoading: 'Entrando'
                    }
                  ]
                }
              ]}
              onSubmit={(data) => handleConnect(data)}
            />
          </>
        </SignContainer>
        
        <Background>
          <img src={BackgroundImage} alt="imgLogin" />
        </Background>
        
        <Footer>
          <main>
              <div>
                <a href="https://www.sodexobeneficios.com.br/?utm_source=site-institucional&utm_medium=rodape" target="_blank" rel="noreferrer">Benefícios e Incentivos</a>
              </div>
              <div>
                <a href="https://sodexoservicos.com.br/contato" target="_blank" rel="noreferrer">Serviços Onsite</a>
              </div>
              <div>
                <a href="https://br.sodexo.com/files/live/sites/com-br/files/Sobre/Politicas/Termos%20e%20Condi%c3%a7%c3%b5es%20Site%202021.pdf" target="_blank" rel="noreferrer">Termos de uso do Portal</a>
              </div>
              <div>
                <a href="https://br.sodexo.com/sodexo-brasil/legal--privacidade/politica-de-privacidade.html" target="_blank" rel="noreferrer">Política de privacidade</a>
              </div>
              <div>
                <a href="https://br.sodexo.com/home.html#" target="_blank" rel="noreferrer">Definições de cookies</a>
              </div>
              <div>
                <a href="https://br.sodexo.com/files/live/sites/com-br/files/Impacto%20Positivo/Pol%c3%adtica%20de%20Cookies%2030112021%20(1).pdf" target="_blank" rel="noreferrer">Política de Cookies</a>
              </div>
          </main>
          <span>
            <FormattedMessage id="copyright" />
            <TagVersion>{version}</TagVersion>
          </span>
        </Footer>
    </Container>
    
      
    </>
  );
};

export default injectIntl(SignIn);