import { datadogRum } from '@datadog/browser-rum';
const env = process.env.REACT_APP_ENV;
const datadogInit = () =>{
    
    if (env === 'development' || env === 'qa') {
        return;
    }
    datadogRum.init({
        applicationId: 'c01c11d0-70a1-4340-a814-6eb1bd48706e',
        clientToken: 'pubb4fbeb93460789d897fb6b6117bd20a4',
        site: 'datadoghq.com',
        service: 'route',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0', 
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};

const dataDogUser = (email)=>{
    datadogRum.setUser({ email: email});
}

export {
    datadogInit,
    dataDogUser
};