import axios from "axios";

var url;

url = process.env.REACT_APP_API_ENDPOINT;

const apiApp = axios.create({
  baseURL: url
});

const loadLanguage = localStorage.getItem("@wizeoTradeRede:lang"); 

if (loadLanguage) {
  apiApp.defaults.headers.common["Accept-Language"] = loadLanguage?.replaceAll(
    '"',
    ""
  );
} else {
  apiApp.defaults.headers.common["Accept-Language"] = "pt_BR";
}

if (localStorage.getItem("@wizeoTradeRede:token")) {
  apiApp.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("@wizeoTradeRede:token")?.replaceAll('"', "");
}

export default apiApp;
