import styled from "styled-components";

export const Flex = styled.body`
  /* position: relative; */
  flex-direction: column;
  align-items: center ;
  height:100vh;
  width:100%;
  /* overflow-y: hidden!important;
  overflow-x: hidden!important; */
  /* position: fixed;  */
`;

export const Header = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  display: flex;
  height: 50px;
  align-items: center;
  box-shadow: 0px 7px 7px -4px rgb(150 150 150 / 50%);
  padding: 0 15px 0 0;
  
  img {
    /* max-width: 140px; */
    /* max-height: 50px; */
    height: 50px;
    /* object-fit: scale-down; */
  }
  
  section {
    display: flex;
    font-size: 13px;
    @media (max-width: 768px) {
      margin-left: auto;
    }
  }
`;

export const ContainerMenu = styled.div`


  @media (max-width: 768px) {
    display: none;
  }
  
  h1 {
    padding: 0px 12px;
    line-height: 38px;
    margin-right: 15px;
    font-weight: bold;
    color: #221c46;
    background-color: #ff7375;
    border-color: #ff7375;
    font-size: 1rem;
    
    svg {
      color: #221c46;
      float: right;
      margin-top: 14px;
      margin-left: 3px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
    
    @media (max-width: 840px) {
        font-size: 9px;
        letter-spacing: none;
      
    }
  }

  display: flex;
  margin-left: auto;
  border-right: 1px solid #B5B2BC;
  margin-right: 15px;
  
  
  div {
    align-self: center;
    top: 2px;
  }
      
  main {
  display: grid;
  grid-template-columns:  300px 70px 70px 100px 70px 100px 70px;
  justify-content: center;
  justify-self: right;
  align-self: center;
  font-size: 14px;
  
    p {
      color: #221C46;
      float: left;
      margin-right: 5px;
      margin-top: -5px;
    }
    
    svg {
      cursor: pointer
    }
    
    .react-switch-bg {
      svg {
        width: 21px;
        height: 21px;
        color: #fff;
      }
      div {
        top: 3px!important;
        text-align: -webkit-center;
      }
    }
  }
`;

export const ContainerMenuSmall = styled.div`

  @media (min-width: 768px) {
    display: none;
  }
  
  main {
    display: grid!important;
    grid-template-columns: 27% 32% 38%;
    width: 100%;
    position: fixed;
    background: #fff;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    height: 35px;
    align-items: center;
    box-shadow: 0px 7px 7px -4px rgb(150 150 150 / 50%);
    place-items: center;
    border-top: 1px solid #f6f6f6;


    font-size: 13px;
    
    div {
      display: grid;
      grid-template-columns: 54% 15% 25%;
      gap: 2%;
      padding: 2%;
    }
    
    p {
      color: #545454;
      float: left;
    }
    
    svg {
      cursor: pointer;
      margin-top: 4px;
    }
    
    .react-switch-bg {
      height: 20px!important;
      width: 40px!important;

      svg {
        width: 21px;
        height: 21px;
        color: #fff;
      }
      div {
        top: -5px!important;
        text-align: -webkit-center;
        margin-left: 9px;
      }
    }
    
    .react-switch-handle {
      height: 0!important;
      width: 0!important;
    }
  }

  nav {
    width: 100%;
    position: fixed;
    background: #fff;
    top: 85px;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    height: 35px;
    align-items: center;
    box-shadow: 0px 7px 7px -4px rgb(150 150 150 / 50%);
    place-items: center;
    border-top: 1px solid #f6f6f6;
    font-size: 13px;
    justify-content: right;
    
    
    h1 {
      margin-right: 15px;
      background-color: #931a28;
      padding: 2px 9px;
      text-transform: uppercase;
      font-size: 9px;
      letter-spacing: 1px;
      width: 120px;
      
      /* padding: 0px 12px; */
      line-height: 27px;
      margin-right: 15px;
      font-weight: bold;
      color: #221c46;
      background-color: #ff7375;
      border-color: #ff7375;
      
      svg {
        color: #fff;
        float: right;
        margin-top: 5px;
        margin-left: 3px;
        cursor: pointer;
        width: 14px;
        height: 14px;
      }
      
    }
  }
  
`;

export const ButtonIcon = styled.div`
  top: 70px;
  text-align: -webkit-right;
  z-index: 9999;
  position: fixed;
  right: 0;
  
  svg {
    width: 35px!important;
    height: 35px!important;
    padding: 5px;
    border-radius: 15px;
    background-color: #931a28;
    color: #fff;
    margin-right: 10px;
    border: 1px solid #fff;
    cursor: pointer
  }
`;

export const SideBar = styled.div`
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    display: none!important;
  }
  
  .pro-sidebar {
    left: ${(props) => (props.collapsed ? "0px" : "0px")};
    z-index: auto;
  }
  
  .pro-sidebar .pro-menu {
    padding-top: 8px;
    padding-bottom: 5px;
    border-right: 1px solid #a5a4a4;
  }
  
  .pro-sidebar > .pro-sidebar-inner {
    width: 400px;
    height: auto;
    border-radius: 0 5px 5px 0;
    margin-top: 50px;
    
    @media (max-width: 768px) {
      margin-top: 120px;
    }
    @media (max-width: 500px) {
      width: 350px;
    }
  }
  
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    display: none;
  }
  
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    display: none;
  }
  
  .pro-sidebar-inner > .pro-sidebar-layout {
    flex-direction: initial;
  }
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 8px 22px;
    
    @media (max-width: 500px) {
      padding: 8px 17px;
    }
  }
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    display: none;
  }
  
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #221C46;
    /* border-radius: 0 5px 5px 0 */
    /* border-radius: 10px; */
  }
  
  
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
    pointer-events: auto;
  }
  
  .pro-sidebar-layout {
    nav:nth-child(7) {
      ul {
        li {
          div {
            span {
              background-color: ${(props) => (props.print  ? " #221C46" : "#221C46")}!important;
              cursor: ${(props) => (props.print ? "pointer" : "auto")}!important;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  
  .circle {
    border-radius: 0;
  }
  
  .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #221C46;
    cursor: pointer;
  } 
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 0px;
  }
 
  svg {
    width: 100px;
    height: 100px;
    padding: 7px;
    color: #fff;
  }
 
`;

export const SideBarIcons = styled.div`
  font-family: "Open Sans",sans-serif;
  
  h4 {
    margin-bottom: 10px;
    font-weight: 600;
    color: #221C46;
    text-transform: uppercase;
  }
  
  .pro-sidebar .pro-menu {
    @media (max-width: 768px) {
      margin-top: 70px;
    }
  }

  .pro-inner-list-item {
      transition: none;
  }
    
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    display: none!important;
  }
  
  .pro-sidebar {
    display: ${(props) => (props.collapsed ? "none" : "")};
    left: 0;
    
    z-index: 99;
    width: ${(props) => (props.collapsed ? "0px" : "400px")};
    
    @media (max-width: 500px) {
      width: ${(props) => (props.collapsed ? "0px" : "350px")};
    }
  }
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    display: none;
  }
  
  .pro-sidebar > .pro-sidebar-inner {
    background: #f8f8f8;
    //aqui
    /* margin-top: 50px; */
    margin-top: 110px;
    height: -webkit-fill-available;
    
  }
  
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    background-color: #f8f8f8;
    box-shadow: 5px 0px 4px -4px rgb(150 150 150 / 50%);
    height: 91%;
    /* border-radius: 5px; */
    
    @media (max-width: 500px) {
      height: 100%;
    }
    @media (max-width: 768px) {
      flex-direction: column!important;
    }
    
    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      /* border-radius: 5px; */
      background: #221C46;
    }
  }
  
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 0px;
  }
  
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background-color: #f8f8f8;
    padding: 0 15px 15px 15px;
    transition-property: height;
    /* height: 0!important; */
    min-height: 400px;
    overflow-y: hidden!important;
    overflow-x: hidden!important;
    
    @media (max-width: 768px) {
      /* min-height: 425px!important; */
    }
  }
  
  .pro-sidebar .pro-menu {
    padding-top: 0;
    padding-bottom: 0;
    
    .close {
      width: 20px;
      height: 20px;
      align-self: flex-end;
      margin: 10px 10px 0px 0px;
      cursor: pointer;
      color: #FF7375
    }
  }
`;

export const RadioButton = styled.div`

p {
  color: #221C46;
  margin-top: 10px;
}
 section {
    text-align: center;
  }
  div {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    /* border-radius: 10px; */
    margin: 15px 0;
  }
  
  
  div label{
    background: #fff;
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    /* border-radius: 10px; */
    cursor: pointer;
    padding: 0 10px;
    border:solid 1px #d9e0e7;
    transition: all 0.3s ease;
    
    @media (max-width: 600px) {
      width: 50%; 
    }
    
    svg {
      width: 20px;
      height: 20px;
      color: #221C46;
    }
  }

  div label .dot::before{
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #FCECEA;
    /* border-radius: 50%; */
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
  }
  
  input[type="radio"]{
    display: none;
  }
  #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2{
    border-color: #221C46;
    background: #FCECEA;
    
    svg {
      color: #221C46
    }
  }
  #option-1:checked:checked ~ .option-1 .dot,
  #option-2:checked:checked ~ .option-2 .dot{
    background: #fff;
  }
  #option-1:checked:checked ~ .option-1 .dot::before,
  #option-2:checked:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
  }
  div label span{
    font-size: 16px;
    color: #545454;
    padding: 4px 5px;
    font-weight: 300;
  }
  #option-1:checked:checked ~ .option-1 span,
  #option-2:checked:checked ~ .option-2 span{
    color: #221C46;
  }
`;

export const Checkbox = styled.div`
  div {
    display: grid;
    grid-template-columns: ${(props) => (props.grid === "evitar" && "37% 29% 33%")};
    grid-template-columns: ${(props) => (props.grid === "filtros" && "25% 30% 22% 23%")};
  }
  
  main {
    display: grid;
    grid-template-columns: 20% 55% 22%;
    padding: 2%;
  }

  margin: 20px 0;
  
  label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 13px;
  color: #545454;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0!important;
  width: 0!important;
}

span {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #221C46 
  /* border-radius: 5px; */
}

label:hover input ~ span {
  background-color: #f6f6f6;
}

label input:checked ~ span {
  background-color: #FF7375;
}
span:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ span:after {
  display: block;
}

label span:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

export const ContainerIndex = styled.div`
  margin: 15px 0;
  position: relative;
  
  display: grid;
  grid-template-columns: 10% 80% 10%;
  
  svg {
    width: 22px;
    height: 22px;
    justify-self: center;
    align-self: center;
    margin-top: -4px;
    color: #221C46
  }

  p {
    margin-bottom: 0px !important;
    padding-left: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #545454;
  }
`;

export const Instructions = styled.div`
  font-family: "Open Sans",sans-serif;

  margin: 30px 10px 15px 10px;
  position: relative;
  color: #545454;
  
  section {
    display: grid;
    grid-template-columns:  40% 60%;
    color: #adadad;
    text-align: center;
  }
  
  h1 {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 25px;
  }
  
  h2 {
      background: #931a28;
      color: #fff;
      font-size: 14px;
      /* border-radius: 3px; */
      text-align: center;
      line-height: 25px;
      margin-top: 10px
    }
  
  
  main {
    display: grid;
    grid-template-columns: 10% 70% 18%;
    gap: 1%;
    margin: 7px 0;
  }
  
  svg {
    width: 22px;
    height: 22px;
    justify-self: center;
    align-self: center;
    margin-top: -4px;
  }

`;

// export const Plus = styled.div`

//   div {
//     display: grid;
//     grid-template-columns: 10% 90%;
//     cursor: pointer;
//     background-color: #931a28;
//     width: fit-content;
//     padding: 5px;
//     border-radius: 10px;
//     padding-left: 15px;
//     margin: 20px 0;
//     float: right;
//     margin-bottom: 50px;
//   }
  
  
//   svg {
//     width: 22px;
//     height: 22px;
//     justify-self: center;
//     color: #fff;
//   }
//   p {
//     padding-left: 10px;
//     color: #fff;
//     font-size: 13px;
//     align-self: center;
//   }
// `;

export const MapContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height:100vh;
  width:100%;

  .gm-ui-hover-effect {
    span {
      width: 20px!important;
    height: 20px!important;
    margin: 0!important;
    margin-top: 10px!important;
    }
   
  }
`;

