import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { IntlProvider } from "react-intl";
import Language, { LanguageProvider } from "./context/language";
import { flattenMessages } from "./utils/flattenMessages";
import messages from "./lang";

//css
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-pro-sidebar/dist/css/styles.css';
import "flag-icons/css/flag-icons.min.css";
import 'rsuite/dist/rsuite.min.css';
import 'react-dropdown/style.css';
import "./index.css";


var locale;

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
    <LanguageProvider>
      <Language.Consumer>
        {({ language }) => {
          if (language) {
            locale = language.replace("_", "-");
          }
          return (
            <IntlProvider
              locale={locale}
              messages={flattenMessages(messages[locale])}
            >
              <App />
            </IntlProvider>
          );
        }}
      </Language.Consumer>
    </LanguageProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)