import React, { useState, useCallback, useContext } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import AuthContext from "./../../context/auth";
import { FiChevronDown } from 'react-icons/fi'

const DropdownUser= () => {
const { user } = useContext(AuthContext);

const [dropDownOpen, setDropDownOpen] = useState(false);

const toggle = useCallback(() => {
  setDropDownOpen(!dropDownOpen); 
}, [dropDownOpen]);


  
const token = localStorage.getItem('@wizeoTradeRede:token')
  
return (
    <Dropdown
    isOpen={dropDownOpen}
    toggle={toggle}
    className="dropdown"
    // tag="li"
  >
    <DropdownToggle
      className="dropdown-toggle"
      tag="a"
      style={{ cursor: "pointer" }}
    >
      <span style={{color:'#2d353c', marginLeft: 30}}>{user.name}</span>
      <FiChevronDown style={{  float: 'right', marginTop: 3, marginRight: 3}}/>

    </DropdownToggle>
    <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
      <a href={`${process.env.REACT_APP_OAUTH_URL}/oidc/endSession?token=${token?.replace(
    /"/g,
    ''
  )}&redirect_uri=${process.env.REACT_APP_URL}/code`}>
        <DropdownItem>
            <span><FormattedMessage id="sair" /></span>
        </DropdownItem>
      </a>
    </DropdownMenu>
  </Dropdown>
);
};

export default DropdownUser;
