import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  margin: 15px 0;
  
  .css-10lk5e0-control, .css-1im77uy-control, .css-hphidw-control {  {
    padding: 3px 0px;
  }


`;

export const List = styled(Select).attrs({})`
  width: 100% !important;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: sans-serif;
  color: ${(props) => (props.value === undefined ? "#fff" : "#545454")};
  
  .css-ego9h2-control, .css-ego9h2-control:hover, .css-hphidw-control {
    border-color: #221C46!important;
    box-shadow: 0 0 0 1px #221C46!important
  }
`;
