import axios from "axios";

async function searchAddress(coordinate) {

  try {

    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinate.lat},${coordinate.lng}&key=AIzaSyBLsefFujLlZVg2M2wqF3pnaQURKV8AGHY`
    );
    

    return response.data;
  } catch (error) {
    return error.message;
  }
}

export { searchAddress };
