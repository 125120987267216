import React, { forwardRef } from "react";

import Toggle from 'react-toggle'
import { Content } from "./styles";

const ToggleComponent = forwardRef((props, ref) => {
  
  const { defaultChecked, onChange, uncheckedicon, checkedicon, oncolor, offcolor, ...rest} = props
  
  return (
    <Content oncolor={oncolor} offcolor={offcolor}>
      <label>
      <Toggle
        ref={ref} 
        {...props}
        {...rest}
        defaultChecked={defaultChecked}
        icons={{
          checked: checkedicon,
          unchecked: uncheckedicon,
        }}
        onChange={onChange} />
      </label>
    </Content>
  );
});

export default ToggleComponent;
