import React, { useState, useCallback }  from "react";
import { useGlobal, setGlobal } from 'reactn';
import { MdAddLocationAlt } from 'react-icons/md'
import { FaTimes } from 'react-icons/fa'
import InputText from '../InputDefault';
import api from "./../../services/api";
import { Autocomplete } from '@react-google-maps/api';
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";

import { Container, Checkbox } from "./styles";

setGlobal({});

const ModalFixLocation = ({intl}) => {
  
  const [listInfos] = useGlobal('listInfos');
  const [newAddress, setNewAddress] = useState('');
  const [number, setNumber] = useState();
  const [incomplete, setIncomplete] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [others, setOthers] = useState(false);
  const [description, setDescription] = useState('');
  
  //MODAL
  const [modalFix, setModalFix] = useGlobal('modalFix');
  
 // LABELS
 const newAddressPlaceholder = intl.formatMessage({ id: "novoEndereço" });
 const numberPlaceholder = intl.formatMessage({ id: "numero" });

  const handleFixLocation = useCallback(async () => {
    if (!newAddress || !number || !(incomplete || incorrect || others) || !description) {
      toast.error("Por favor, preencha todos os campos corretamente.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored"
      });
      
      return
    }
    
    try {
      
      var id = listInfos?.id;
      var formattedIncomplete =  incomplete === true ? 1 : 0;
      var formattedIncorrect =  incorrect === true ? 1 : 0;
      var formattedOthers =  others === true ? 1 : 0;
      
      await api.post(
        `merchants/adjustment-register?merchant_id=${id}&new_address=${newAddress}&number=${number}&incomplete=${formattedIncomplete}&incorrect=${formattedIncorrect}&others=${formattedOthers}&description=${description}`
      );
      
      setModalFix(false);
      
      setNewAddress("");
      setNumber();
      setIncomplete(false);
      setIncorrect(false);
      setOthers(false);
      setDescription("");
      
      toast.success("Email enviado com sucesso!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored"
      });
      
    } catch (error) {
      toast.error("Erro. Tente novamente", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored"
      });
    }
  }, [listInfos, newAddress, number, incomplete, incorrect, others, description, setModalFix]);
  
  return (
    <>
      {modalFix && (
       <Container color={"#931a28"}>
         <header>
          <MdAddLocationAlt/> 
          <p><FormattedMessage id="corrigirLocalização" /></p> 
          <FaTimes onClick={() => setModalFix(false)} style={{cursor: 'pointer'}}/>
         </header>
         
         <nav>
            <Autocomplete>
              <InputText
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
                onBlur={(e) => setNewAddress(e.target.value)}
                placeholder={newAddressPlaceholder}
                type="text"
              />
            </Autocomplete>
            
            <InputText
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder={numberPlaceholder}
              type="number"
            />
            
            <h1>
            <FormattedMessage id="motivo" />:
            </h1>
            
              <Checkbox>
                <main>
                  <label>
                    <InputText 
                      type="checkbox"   
                      onChange={() => {
                        setIncomplete(!incomplete);
                      }}
                      checked={incomplete}
                    />
                    <span></span>
                  </label>
                  <p><FormattedMessage id="cadastroIncompleto" /></p>
                  
                </main>
              </Checkbox>
              
              <Checkbox>
                <main>
                  <label>
                    <InputText 
                      type="checkbox"   
                      onChange={() => {
                        setIncorrect(!incorrect);
                      }}
                      checked={incorrect}
                    />
                    <span></span>
                  </label>
                  <p><FormattedMessage id="localizaçãoIncorreta" /></p>
                </main>
              </Checkbox>
              
              <Checkbox>
                <main>
                  <label>
                    <InputText 
                      type="checkbox"   
                      onChange={() => {
                        setOthers(!others);
                      }}
                      checked={others}
                    />
                    <span></span>
                  </label>
                  <p><FormattedMessage id="outros" /></p>
                </main>
              </Checkbox>
              
              <h1>
              <FormattedMessage id="descricao" />:
              </h1>
              <textarea 
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                maxLength={100}
              />
              
              <footer onClick={() => handleFixLocation()}>
                 <p><FormattedMessage id="enviar" /></p>
               </footer>
              
            </nav>
    
       </Container>
      )}
    </>
  );
};

export default injectIntl(ModalFixLocation);
