import React, { forwardRef } from "react";

import Slider from 'rsuite/Slider';
import { Content } from "./styles";

const SliderRange = forwardRef((props, ref) => {
  
  const { value, onChange, labelmin, labelmax, type, ...rest} = props
  
  var labels = []; 
  if (type === 'rota'){
    labels = ['100m', '300m', '500m', '1km', '2km', '3km', '5km'];
  } 
  if (type === "raio") {
    labels = ['500m', '1km', '3km', '5km', '7km', '10km', '15km'];
  }
  
  return (
    <Content>
      <section>
        <h1>{labelmin}</h1>
        <h2>{labelmax}</h2>
      </section>
      <Slider
        progress
        min={0}
        max={6}
        graduated
        tooltip={false} 
        ref={ref}
        {...props}
        {...rest}
        value={value}
        onChange={onChange}
      />
      
      <section>
        <h3>{labels[value]}</h3>
      </section>
    </Content>
  );
});

export default SliderRange;
