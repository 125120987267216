import React from "react";
import {
  BrowserRouter,
  useRoutes,
} from "react-router-dom";
import Login from "../pages/SignIn";
import Code from "../pages/SignIn/code";

const App = () => useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/code", element: <Code /> },
  ]);

const AuthRoutes = () => (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
  
  export default AuthRoutes;