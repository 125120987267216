import React from "react";

import ReactTooltip from 'react-tooltip';
import { Content } from "./styles";

const Tooltip = ({ id, text, place, html, color, className, route, event, ...rest }) => {

  return (
    <Content route={route}>
       <ReactTooltip id={id}
        getContent={(dataTip) => <>{text}</> }
        effect='solid'
        delayHide={100}
        delayShow={100}
        delayUpdate={100}
        place={place}
        border={true}
        backgroundColor={color ? color : "#FF7375"}
        className={className ? className : 'tooltip'}
        event={event ? event : ""}
        globalEventOff={event ? event : ""}
      /> 
    </Content>
  );
};

export default Tooltip;
