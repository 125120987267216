import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./../context/auth";
import api from "./../services/api";
import AuthRoutes from "./auth.routes";
import AppRoutes from "./app.routes";
import { toast } from "react-toastify";
import '@dilicorp/ui/dist/css/style.min.css';

const Routes = () => {
  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          toast.error("Você perdeu sua conexão, Faça o login novamente.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored"
          })
          setTimeout(() => {
            document.location.reload(true);

          }, 5000)
        }
        return;
      }
    );
  }, []);
  
  const { signed } = useContext(AuthContext);

  return (
    <>
      {signed ? (
         <AppRoutes />
      ) : (
        <>
          <AuthRoutes />
        </>
      )}
    </>
  );
};

export default Routes;