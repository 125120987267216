import styled from "styled-components";

export const Content = styled.div`

top: 1px!important;

  @media (max-width: 768px) {
    .react-toggle-track{
      height: 20px !important;
      width: 40px !important;
    }
    .react-toggle-thumb {
      height: 0px !important;
      width: 0px !important;
    }
    .react-toggle-track-check svg {
      margin-top: -5px!important;
      margin-left: 2px!important;
    }
    .react-toggle-track-x svg {
      margin-top: -5px!important;
      margin-left: -20px!important;
    }
    .react-toggle--checked .react-toggle-thumb{
      background: transparent!important ;
    }
  }
 
.react-toggle {
  height: 28px;
    width: 56px;
    margin: 0px;
    position: relative;
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
    

}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  height: 28px;
  width: 56px;
  padding: 0;
  border-radius: 30px;
  background-color: ${(props) => props.offcolor };
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #000000; */
}

.react-toggle--checked .react-toggle-track {
  background-color: ${(props) => props.oncolor };
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: ${(props) => props.oncolor };

}

.react-toggle-track-check {
  /* position: absolute;
  width: 14px;
  height: 10px;
  top: -10px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 6px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; */
  
  position: absolute;
  bottom: 9px;
  margin-top: 4px;
  margin-bottom: auto;
  line-height: 0;
  left: 10px;
  opacity: 0;
  
  img, svg {
    width: 16px;
    height: 16px;
    color: #221C46;
  }
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -10px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 16px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  
  svg {
    width: 21px;
    height: 21px;
    color: #fff;
  }
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-color: ${(props) => props.oncolor };
  
  height: 26px;
  width: 26px;
  background: rgb(255,255,255);
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  -webkit-transform: translateX(1px);
  -ms-transform: translateX(1px);
  transform: translateX(1px);
  top: 1px;
  outline: 0px;
  border: 0px;
}

.react-toggle--checked .react-toggle-thumb {
  /* left: 27px; */
  border-color: ${(props) => props.oncolor };
  height: 26px;
    width: 26px;
    background: rgb(255, 255, 255);
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    transform: translateX(29px);
    top: 1px;
    outline: 0px;
    border: 0px;
    transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}

.react-toggle--focus .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 3px 2px #a5a5a5;
  -moz-box-shadow: 0px 0px 3px 2px #a5a5a5;
  box-shadow: 0px 0px 2px 3px #a5a5a5; */
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 5px 5px #a5a5a5;
  -moz-box-shadow: 0px 0px 5px 5px #a5a5a5;
  box-shadow: 0px 0px 5px 5px #a5a5a5; */
}
`;
