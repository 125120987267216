import styled from "styled-components";

export const Content = styled.div`

padding: 0 15px 10px 15px;
font-family: "Open Sans",sans-serif;
  
  section{
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 14px!important;
    
    h1 {
      color: #221C46;
    }
    
    h2 {
      justify-self: end;
      line-height: 62px;
      color: #221C46;
    }
    
    h3 {
      background: #221C46;
      color: #fff !important;
      font-size: 11px!important;
      /* border-radius: 3px; */
      text-align: center;
      width: 50px;
      line-height: 25px;
      margin-top: 10px
    }
  }
  
  .rs-slider {
    margin-top: -10px; 
  }
  
  .rs-slider-progress-bar {
    background-color: #221C46
  }
  
  .rs-slider-handle:before{
    border: 2px solid #fff;
  }
  
  .rs-slider-graduator>ol>li.rs-slider-pass:before{
    border: 1px solid #7e7e7e;
  }
  
  .rs-slider-graduator>ol>li:before, .rs-slider-graduator>ol>li:last-child:after{
    border: 1px solid #7e7e7e;
  }
  
  .rs-slider-bar {
    background-color: #B5B2BC;
  }
  
  .rs-slider-handle:before{
    top: -3px;
    width: 18px;
    height: 18px;
    background-color: #FF7375
  }
`;
