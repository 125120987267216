import React from 'react';
import Routes from "./routes/index";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from "./../src/context/auth";
import { useEffect } from 'react';
import {datadogInit} from './utils/datadog';


export default function App() {
   useEffect(() => {
      datadogInit();
   });
   return (
     <AuthProvider>
        <Routes/>
        <ToastContainer autoClose={3500} pauseOnHover={false} />
     </AuthProvider>
   );
}