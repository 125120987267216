import styled from "styled-components";

export const Container = styled.div`
  top: 125px;
  position: absolute;
  height: 475px;
  width: 340px;
  background: #fff;
  z-index: 9998;
  box-shadow: 1px 5px 6px #666;
  left: 10px;
  border-radius: 10px;
  
  header {
    height: 50px;
    background-color: ${(props) => props.color };
    border-radius: 10px 10px 0 0 ;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    padding: 0 0 0 15px;
    align-content: center;
    
    svg {
      width: 21px;
      height: 21px;
      color: #fff;
    }
    
    p {
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
    }
  }
  
  nav {
    padding: 15px;
    
    input {
      margin-bottom: 5px;
    }
  }
  
  textarea {
    background: #fff;
    color: #000;
    font-size:14px;
    padding: 10px;
    /* font-weight: 500; */
    width: 100% !important;
    height: 70px!important;
    border: solid 1px #d9e0e7;
    border-radius: 5px;
    outline: 0;
    font-family: "Open Sans",sans-serif;
    font-weight: 300;
    
    :focus {
      border: 2px solid #931a28;
    }
  }
 
  h1 {
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: #545454;
    letter-spacing: 1px;
    margin: 10px 0;
  }
  
  main {
    
    p {
      color: #545454;
    }
    
    label {
      justify-self: right;
    }
  }
  
  footer {
    cursor: pointer;
    background-color: #931a28;
    border-radius: 10px;
    margin: 20px 0;
    padding: 8px;
    bottom: 0;
    text-align: center;
    width: 100%;
    
    p {
      color: #fff;
      font-weight: 600;
    }
  }
`;

export const Checkbox = styled.div`
  margin: 10px 0;

  main {
    display: grid;
    grid-template-columns: 10% 90%;
    margin-bottom: 15px;
  }

  label {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 13px;
  color: #545454;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0!important;
  width: 0!important;
}

span {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #221C46 
  border-radius: 5px;
}

label:hover input ~ span {
  background-color: #f6f6f6;
}

label input:checked ~ span {
  background-color: #FF7375;
}

span:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ span:after {
  display: block;
}

label span:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;