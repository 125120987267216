import styled from "styled-components";

export const Content = styled.div`
  width: 310px;
  height: 400px;
  padding-top: 15px;
  color: #545454;
  font-family: "Open Sans",sans-serif;
  
  header {
    background-color: #FF7375;
    color: #fff;
    padding: 8px;
    /* border-radius: 5px; */
    width: 100%;
    text-transform: uppercase;
    color: #221C46;
    font-weight: bold
  }
  
  nav {
    display: grid;
    grid-template-columns: 20% 35% 24% 21%;
    justify-items: center;
    background-color: #f6f6f6;
    /* border-radius: 10px; */
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  section {
    padding: 0 10px;
    color:  #221C46
  }
  
  h3 {
    cursor: pointer;
    line-height: 30px;
  }
  
  h3:hover {
    border-bottom: 2px solid #FF7375;
    padding: 0px 7px;
    /* border-radius: 3px; */
  }
  
  h4 {
    svg {
      height: 25px;
      width: 25px;
      margin-right: 10px;
      color: #545454;
    }
    color: #545454;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;
  }
  
  h5 {
    text-transform: uppercase;
    font-weight: bold;
    /* font-size: 14px;    */
  }
 
  div {
    margin-bottom: 15px;
  }
  
  .star {
    float: right;
    cursor: pointer
    }

  .star:hover {
    color: #f1e22a !important;
    transition: 0.5s;
    cursor: pointer;
  }
  
  .selected {
    border-bottom: 2px solid #FF7375;
    padding: 0px 7px;
    background-color: #ededed;
    /* border-radius: 3px; */
    color:#221C46;
    font-weight: bold
  }
  
  svg, img {
    float: left;
    height: 30px;
    margin-right: 10px;
    width: 30px;
    color: #3a9b1a;
  }
  
  
  .container-image {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    color: #545454;
    
    img {
      object-fit: scale-down;
      width: 100%;
      height: 100px;
      align-self: center;
      padding: 10px;
      /* border-radius: 10px; */
      border: 1px solid #d7d7d7;
      cursor: pointer;
    }
  }
  
  .container-preço {
    display: grid;
    grid-template-columns: 48% 26% 22%;
    gap: 2%;
    
    overflow-y: auto;
      overflow-x: auto;
        ::-webkit-scrollbar-track {
        background-color: #221C46;
      }
      ::-webkit-scrollbar {
        width: 8px;
        background: #242b30;
      }
      ::-webkit-scrollbar-thumb {
        /* border-radius: 5px; */
        background: #221C46;
      }
    
    div {
      margin-top: 10px;
      margin-bottom: 5px;
      align-self: center;
      font-size: 11.5px;
      font-weight: lighter;
      
      img {
        height: 20px;
        width: 20px;
        margin-right: 3px;
        color: #931a28;
        margin-top: -3px;
      }
    }
    
    
    h6 {
      border-bottom: 1px solid  #d7d7d7;
      margin-bottom: 3px;
      font-weight: 500;
    }
  }
  
  h1 {
      font-size: 12px;
      color:#221C46;
      line-height: 15px;
    }

  footer {
    
    cursor: pointer;
    background-color: #931a28;
    /* border-radius: 10px; */
    margin-top: 15px;
    padding: 8px;
    bottom: 0;
    text-align: center;
    /* width: 285px;
    position: absolute; */
    width: 100%;
    
    @media (min-width: 768px) {
      position: fixed;
      width: 290px;
      margin-bottom: 20px;
    }

    
    p {
      color: #fff;
      font-weight: 600;
    }
  }
`;

export const ModalCarousel = styled.div`
  width: 45%;
  margin-top: 80px;
  z-index: 99999;
  background: #fff;
  padding: 10px;
  /* border-radius: 10px; */
  box-shadow: 1px 5px 6px #666;
  
  svg {
    margin-bottom: 7px;
    float: right;
    width: 18px;
    height: 18px;
    color: #adadad;
    cursor: pointer;
  }
  
  .carousel .slider-wrapper{
    img {
      object-fit: scale-down;
      height: 300px;
      padding: 5px;
    }
  }

  .carousel .control-dots .dot{
      background: #931a28;
  }
  
  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    border: solid 2px #d7d7d7;
    /* border-radius: 5px; */
  }
  
  .carousel .carousel-status {
    text-shadow: none;
  }
  
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid #242b30
  }
`;