import styled from "styled-components";

export const Container = styled.div`
  .spinner {
    height: 26px!important;
    width: 26px!important;
  }
  .spinner.spinner-primary {
    border-left-color: #221c46;
  }
  
  .btn.disabled, .btn:disabled {
    /* opacity: .5; */
  }
`;