import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  font-family: "Open Sans",sans-serif;
  
  .rodape{
    font-family: "Open Sans",sans-serif;
    width: 100%;
    bottom: 0px;
    background: #282151ed;
    padding-bottom: 4px 5px;

    @media (max-width: 500px) {
      width: 100%
    }

    @media (min-height: 500px) {
      position: absolute;
    }
  }

  .grid{
    display: grid;
    gap: 2%;
    margin: 4px;

    @media (min-height: 500px) {
      grid-template-columns: 10% 8% 12% 10% 10% 10%;
    }

    @media (max-width: 500px) {
      grid-template-columns: 30% 30% 30%;
    }
  }
  .textLgpd {
    color: #fff;

    a {
      color: #fff;
      font-size:12px;
    }
  }

  .copyright {
    color: #fff;
    padding-left: 4px;
  }

  .form-pluxee-conect {
    padding: 0 2rem;
  }
`;

export const Footer = styled.footer`
    font-family: "Open Sans",sans-serif;
    width: 100%;
    bottom: 0px;
    background: #282151ed;
    padding-bottom: 4px 5px;
    color: #fff;
    font-size:12px;

    @media (max-width: 500px) {
      width: 100%
    }

    @media (min-height: 500px) {
      position: absolute;
    }
    
    @media (max-height: 500px) {
      display: none;
    }

  main{
    display: grid;
    gap: 2%;
    margin: 4px;

    @media (min-height: 500px) {
      grid-template-columns: 10% 8% 12% 10% 10% 10%;
    }

    @media (max-width: 500px) {
      grid-template-columns: 30% 30% 30%;
    }
  }
  

 span {
    color: #fff;
    padding-left: 4px
  }
`;

export const TagVersion = styled.span`
  opacity: 50%;
  margin-left: 15px;
`;

export const SignContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  
  > img {
    position: absolute;
    width: 200px;
    top:0;
    left: 0;
  }
  
  .react-switch-bg{
  top: 3px!important;
  } 
  
  .react-switch-handle{
  top: 4px!important;
  }
  
  @media (max-height: 500px) {
    max-width: none;
    flex-direction: initial;
    align-items: flex-start;
    height: 150vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > form {
    // display: flex;
    flex-direction: column;
    width: 75%;
    header {
      margin-top: 5px;
      margin-bottom: 24px;

      button {
        font-size: 14px;
      font-weight: 500;
      }
      h5 {
        font-weight: bold;
        /* line-height: 25px; */
        font-size: 18px!important;
        color: #221C46;
        letter-spacing: 0px;
      }
    }
    main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      p {
        color: #707478;
      }
      a {
        color: #0d6efd;
        text-decoration: underline;
      }
    }
    > span {
      color: #707478;
      margin-top: 15px;
      text-align: center;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  width: auto;
  height: 100%;
  z-index: -1;
  background-color: #fcfeff;
  img {
    width: 100%;
    height: 100%;
    @media (max-width: 850px) {
      object-fit: cover;
    }
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #ededed;
  margin-top: 10px;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  > :nth-child(1) {
    width: 200px;
    height: 60px;
    margin-bottom: 7px;
    display: none;
    @media (max-width: 750px) {
      display: block;
      width: 180px;
      height: 80px;
    }
  }
  > :nth-child(2) {
    width: 200px;
    height: 60px;
    margin-bottom: 7px;
    @media (max-width: 515px) {
      width: 120px;
      height: 40px;
    }
  }
`;
