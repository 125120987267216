import styled from "styled-components";

export const Content = styled.div`

    font-family: "Open Sans",sans-serif;
    z-index: 9999;
    
  .tooltip {
    
    opacity: 1!important;
    /* border-radius: 10px; */
    padding: 0;
    
    h3 {
      text-transform: uppercase;
      padding: 8px 20px;
      color: #221C46;
    }
    
    div {
      height: 200px!important;
      
      @media (max-width: 768px) {
        width: ${(props) => (props.route && "240px!important")};
      }
      
      h2 {
        text-transform: uppercase;
        padding: 5px 20px;
        text-align: center;
      }
      
      span {
        text-transform: uppercase;
        padding: 10px 0;
        font-weight: 600!important
      }
      
      img {
        width: 23px;
        height: 23px;
        justify-self: auto;
      }

      overflow-y: auto;
      overflow-x: hidden!important;
        ::-webkit-scrollbar-track {
        background-color: #242b30;
      }
      ::-webkit-scrollbar {
        width: 8px;
        background: #242b30;
      }
      ::-webkit-scrollbar-thumb {
        /* border-radius: 5px; */
        background: #221C46;
      }

      > main {
        cursor: ${(props) => (props.route ? "" : "pointer" )};
        
        display: grid;
        grid-template-columns: ${(props) => (props.route ? "" : "40px 70%" )};
        
        border-bottom: 1px solid #fff;
        border-width: 90%;
        margin: 0 15px;
      }
     
    }
  }
`;
