import React, { useState, useCallback, useEffect, memo, useMemo } from 'react'
import { Flex, SkeletonText } from '@chakra-ui/react'
import api from "./../../services/api";
import { searchCoordinate } from "./../../services/coordinates";

import {
  useJsApiLoader,
  GoogleMap,
  InfoWindow,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Circle,
  StreetViewPanorama,
} from '@react-google-maps/api';

import {
  SideBar,
  SideBarIcons,
  ContainerIndex,
  RadioButton,
  Checkbox,
  Header,
  MapContainer,
  ButtonIcon,
  ContainerMenu,
  ContainerMenuSmall
} from "./styles";

import { FaTimes, FaRoute } from 'react-icons/fa'
import { FiCircle, FiMapPin, FiTrash2, FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { MdAddBusiness } from 'react-icons/md'
import { RiFilterOffFill, RiGasStationFill } from 'react-icons/ri'
import { AiOutlineCar, AiOutlinePlusCircle, AiOutlineTool, AiFillPrinter, AiFillStar } from 'react-icons/ai'
import { BiBusSchool } from 'react-icons/bi'

import { FormattedMessage, injectIntl } from "react-intl";

import Pluxee from "./../../assets/pluxee-new.png";
import iconRota from "./../../assets/rota.png";
import iconRaio from "./../../assets/raio.png";
import iconRede from "./../../assets/rede.png";
import iconLista from "./../../assets/lista.png";
import iconListaDisable from "./../../assets/lista-disable.svg";
import iconFiltro from "./../../assets/filtro.png";
import iconBomba from "./../../assets/bomba.png";
import nobrandIcon from "./../../assets/nobrand.png"
import iconFerramentas from "./../../assets/ferramentas.svg";

import Tooltip from '../../components/Tooltip';
import SliderRange from '../../components/SliderRange';
import Button from '../../components/ButtonModal';
import DropDown from '../../components/DropDown';
import InputText from '../../components/InputDefault';
import Language from "../../components/Language";
import User from "../../components/User";
import LoaderContent from "../../components/Spinner";

import Toggle from "../../components/Toggle";
import ModalPrint from "../../components/ModalPrint";
import { ModalInfo, CarouselImage } from '../../components/ModalInfo';
import ModalFilter from '../../components/ModalFilter';
import { ProSidebar, Menu, SubMenu } from 'react-pro-sidebar';
import { toast } from "react-toastify";
import { useGlobal, setGlobal } from 'reactn';
import ModalFixLocation from '../../components/ModalFixLocation';
import { MerchantAPI } from "../../services/merchants";

setGlobal({});

const App = ({ intl }) => {

  const [loading, setLoading] = useState(false);

  const [services, setServices] = useGlobal('services');
  const [brands, setBrands] = useGlobal('brands');

  const [distanceKM, setDistanceKM] = useState(0);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [directionsResponse, setDirectionsResponse] = useGlobal('directionsResponse')
  const [center, setCenter] = useState()
  const [listEstablishments, setListEstablishments] = useGlobal('listEstablishment');
  const [markerRaio, setMarkerRaio] = useState([])
  const [streetView, setStreetView] = useState(false);

  // MODAL INFO
  const [openModal, setOpenModal] = useGlobal('openModal');
  const [indexModal, setIndexModal] = useState(null);

  // MODAL IMPRESSÃO
  const [modalEstabelecimentos, setModalEstabelecimentos] = useGlobal('modalEstabelecimento');
  const [showEstablishments, setShowEstablishments] = useState(false);

  // TOP MENU
  const [filterPostos, setFilterPostos] = useState(true);
  const [modalFilterPostos, setModalFilterPostos] = useGlobal('modalFilterPostos');
  const [filterServiços, setFilterServiços] = useState(true);
  const [modalFilterServiços, setModalFilterServiços] = useGlobal('modalFilterServiços');
  const [filterFavoritos, setFilterFavoritos] = useState(false);
  const [modalFilterRoutes, setModalFilterRoutes] = useGlobal('modalFilterRoutes');

  // SIDE MENU
  const [collapsed, setCollapsed] = useGlobal('collapsed');
  const [menuOpen, setMenuOpen] = useState("rota");
  const [menuSearch, setMenuSearch] = useState("");

  // ROTA
  const [rota, setRota] = useState("");
  const [rangeRota, setRangeRota] = useState(3);
  const [pedagio, setPedagio] = useState(false);
  const [balsa, setBalsa] = useState(false);
  const [rodovia, setRodovia] = useState(false);
  const [pontoPartida, setPontoPartida] = useGlobal("pontoPartida");
  const [endereços, setEndereços] = useGlobal("endereços");

  // RAIO
  const [endereçoRaio, setEndereçoRaio] = useState("");
  const [rangeRaio, setRangeRaio] = useState(3);

  // REDE 
  const [estabelecimento, setEstabelecimento] = useState("");
  const [estadoEstabelecimento, setEstadoEstabelecimento] = useState("");
  const [municipioEstabelecimento, setMunicipioEstabelecimento] = useState("");

  // OPÇÕES 
  const [optionsEstado, setOptionsEstado] = useState([]);
  const [optionsMunicipio, setOptionsMunicipio] = useState([]);

  const disableRouterSearchButton = useMemo(() => {
    const origin = pontoPartida;
    const destination = endereços?.length ? endereços[endereços.length - 1].local : '';
    return !(origin && destination)
  }, [pontoPartida, endereços])

  const disableCitySearchButton = useMemo(() => {
    var cityFilter = municipioEstabelecimento?.label ? municipioEstabelecimento?.label : "";
    return !(cityFilter || estabelecimento)
  }, [municipioEstabelecimento, estabelecimento])

  const disableRadiusSearchButton = !Boolean(endereçoRaio?.length)

  // LABELS
  const startPlaceholder = intl.formatMessage({ id: "pontoPartida" });
  const destinationPlaceholder = intl.formatMessage({ id: "informeDestino" });
  const addressPlaceholder = intl.formatMessage({ id: "digiteEndereço" });
  const statePlaceholder = intl.formatMessage({ id: "estado" });
  const cityPlaceholder = intl.formatMessage({ id: "municipio" });
  const establishmentPlaceholder = intl.formatMessage({ id: "estabelecimento" });

  const onRangeNumber = useCallback((type, number) => {
    if (type === 'route') {
      if (number === 0) return 0.001
      if (number === 1) return 0.003
      if (number === 2) return 0.005
      if (number === 3) return 0.01
      if (number === 4) return 0.02
      if (number === 5) return 0.03
      if (number === 6) return 0.05
    }

    if (type === 'raio') {
      if (rangeRaio === 0) return 0.005
      if (rangeRaio === 1) return 0.01
      if (rangeRaio === 2) return 0.03
      if (rangeRaio === 3) return 0.05
      if (rangeRaio === 4) return 0.07
      if (rangeRaio === 5) return 0.10
      if (rangeRaio === 6) return 0.15
    }

    if (type === 'circle') {
      if (rangeRaio === 0) return 500
      if (rangeRaio === 1) return 1000
      if (rangeRaio === 2) return 3000
      if (rangeRaio === 3) return 5000
      if (rangeRaio === 4) return 7000
      if (rangeRaio === 5) return 10000
      if (rangeRaio === 6) return 15000
    }
  }, [rangeRaio]);

  const setMerchantData = (data) => {
    return {
      id: data.id,
      favorite: data?.user,
      showAllFavorites: true,
      icon: data.brand_icon,
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
      brand_name: data.brand_name,
      activity: data.activity,
      show: true, // exibir estabelecimento no mapa
      showBrand: true, // exibir estabelecimento no mapa de acordo com as bandeiras selecionadas
      brandSelect: true, // informar se a bandeira está selecionada
      showAllBrands: true, // informar se a exibição de todos os postos é V ou F

      showActivity: true, // exibir estabelecimento no mapa de acordo com as atividades selecionadas
      activitySelect: true, // informar se a atividade está selecionada
      showAllActivities: true, // informar se a exibição de todas atividades é V ou F

      preferential: data.preferential
    }
  }

  const loadEstablishments = useCallback(() => {
    setLoading(true);
    try {
      MerchantAPI.fetchAll(100, null, (merchants) => {
        const formattedList = merchants.map((m) => {
          return setMerchantData(m);
        });

        setListEstablishments(formattedList);
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }, [setListEstablishments]);

  const loadPosition = useCallback(async () => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setCenter({ lat: latitude, lng: longitude })
      setZoom(15)
    });

    if (center === undefined) {
      setCenter({ lat: -11.708113517116136, lng: -59.74509516821192 });
      setZoom(5);
    }
  }, [center]);

  const loadStates = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/states`);
      const formattedStates = response.data.map((state) => {
        const obj = {};

        Object.assign(obj, {
          value: state.id,
          label: state.abbreviation,
          lat: parseFloat(state.latitude),
          lng: parseFloat(state.longitude),
          zoom: parseFloat(state.zoom)
        });

        return obj;
      });

      setOptionsEstado(formattedStates)
    } catch (error) {
      console.log(error)
    }
  }, []);

  const loadCities = useCallback(async (id) => {
    try {
      const { data: response } = await api.get(`/cities?state_id=${id}`);
      const formattedCities = response.data.map((city) => {
        const obj = {};

        Object.assign(obj, {
          value: city.name,
          label: city.name,
          lat: parseFloat(city.latitude),
          lng: parseFloat(city.longitude),
        });

        return obj;
      });

      setOptionsMunicipio(formattedCities)
    } catch (error) {
      console.log(error)
    }
  }, []);

  const calculateRoute = useCallback(async (infos) => {

    setCollapsed(true);
    setLoading(true);
    setMarkerRaio([]);
    setModalEstabelecimentos(false);
    // setFilterFavoritos(false);
    setMenuSearch("rota");

    var origin = "";
    var destination = "";
    var formattedWaypoints = [];

    const directionsService = new window.google.maps.DirectionsService();
    // const distanceService = new window.google.maps.DistanceMatrixService();

    if (infos.name) {
      origin = infos.initial;
      var adresses = infos.adresses?.filter(
        (address) => address !== null
      );

      destination = adresses[adresses.length - 1];

      formattedWaypoints = adresses?.filter(
        (endereço, index) => adresses.length - 1 !== index
      );

      formattedWaypoints = formattedWaypoints?.map((endereço) => {
        const obj = {};

        Object.assign(obj, {
          location: endereço
        });
        return obj;
      });

      // setPontoPartida(infos.initial)
    } else {
      origin = pontoPartida;
      destination = endereços[endereços.length - 1].local;

      formattedWaypoints = endereços?.filter(
        (endereço, index) => endereços.length - 1 !== index
      );

      formattedWaypoints = formattedWaypoints?.map((endereço) => {
        const obj = {};

        Object.assign(obj, {
          location: endereço?.local
        });
        return obj;
      });
    }

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        avoidFerries: infos.ferries ? infos.ferries : balsa,
        avoidHighways: infos.highways ? infos.highways : rodovia,
        avoidTolls: infos.tolls ? infos.tolls : pedagio,
        waypoints: formattedWaypoints,
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setListEstablishments([]);
          setDirectionsResponse(result);

          var distanceTotal = 0
          result.routes[0].legs.forEach((distance) => {
            distanceTotal = distanceTotal + distance.distance.value
          })

          var distanceFormatted = (distanceTotal / 1000).toFixed(1)

          setDistanceKM(distanceFormatted);

          let ne = result.routes[0].bounds.getNorthEast();
          let sw = result.routes[0].bounds.getSouthWest();
          var lng1 = sw.lng(); var lng2 = ne.lng(); var lat1 = sw.lat(); var lat2 = ne.lat();

          async function loadRouterMarker() {
            try {
              // var validateType = infos.typeRoute ? infos.typeRoute : rota;
              // var validateRadius = infos.radius || infos.radius === 0 ? infos.radius : rangeRota;
              // var idRoute = infos?.id

              // const { data: response } = await api.get(
              //   `merchants?columns=brand_icon,brand_name,street,number,state,city,company_name,trade_name,activity,phone,preferential&bounds[]=${lat1}&bounds[]=${lng1}&bounds[]=${lat2}&bounds[]=${lng2}&bounds[]=4529&route=${validateType}&favorites=true&route_id=${idRoute}&productId=10`
              // );

              // Get all route points
              const routeLocations = result.routes[0].overview_path.map(route => {
                return { latitude: route.lat(), longitude: route.lng() };
              })

              // Get initial and final route points
              const userLocation = routeLocations.at(-1)
              const currentLocation = routeLocations.at(0)

              // Get all products
              const { data: responseProducts } = await api.get('products');
              const products = responseProducts.data.map(i => i.sodexo).join(',')

              // Fetching all locations in route
              const locations = await MerchantAPI.fetchLocations(products, {
                userLocation,
                routeLocations,
                currentLocation,
                locations: { currentLocation: { ...currentLocation } }
              })

              var displayed = [];

              let formattedList = [];

              let data = locations.data.entries();

              for (const [index, establishment] of data) {
                // response.data.map((establishment, index) => {
                // var radius = window.google.maps.geometry.poly.isLocationOnEdge({ lat: parseFloat(establishment.latitude), lng: parseFloat(establishment.longitude) }, new window.google.maps.Polyline({
                //   path: window.google.maps.geometry.encoding.decodePath(result.routes[0].overview_polyline)
                // }), onRangeNumber('route', 6));
                // console.log(validateRadius);

                // if (radius){
                // displayed.push(establishment.id)
                // }

                // let count = 0;
                // let brandSelect = false;
                // let showAllBrands = true

                // if ((establishment.activity === "POSTOS COMBUSTIVEIS" || establishment.activity === "ESTACION DE SERVICIOS" || establishment.activity === "GASOLINERA")){
                //   brands?.forEach((brand) => {
                //     if ((brand?.name === establishment?.brand_name) && brand?.show === true) {
                //       brandSelect = true;
                //     }
                //     if (brand?.show === true){
                //       count = count + 1
                //     }
                //   });
                //   showAllBrands = filterPostos;
                // }

                /////////////////////////////////////////////

                // let countActivity = 0;
                // let activitySelect = false;
                // let showAllActivities = true

                // if ((establishment.activity !== "POSTOS COMBUSTIVEIS" && establishment.activity !== "ESTACION DE SERVICIOS" && establishment.activity !== "GASOLINERA")) {
                //   services?.forEach((service) => {
                //       activitySelect = true;
                //       countActivity = countActivity + 1
                //   })

                //   showAllActivities = filterServiços
                // }

                /////////////////////////////////////

                // var showFavorite = false

                // if (filterFavoritos === true) {
                //   if (establishment.user[0]) {
                //     showFavorite = filterFavoritos
                //   } else {
                //     showFavorite = false
                //   }
                // } else {
                //   showFavorite = true
                // }

                let formattedProducts = establishment?.items?.map((product) => {
                  let obj2 = {};
                  Object.assign(obj2, {
                    id: product.id,
                    data: product.date,
                    preço: product.price,
                    nome: product.description,
                    show: false
                  });
                  return obj2
                });

                let obj = {};
   
                Object.assign(obj, {
                  id: establishment?.merchant?.id,
                  index_establishment: index,
                  favorite: false,
                  showAllFavorites: false,
                  icon: establishment?.merchant?.photo?.flag,
                  lat: parseFloat(establishment.merchant.coordinates.latitude),
                  lng: parseFloat(establishment.merchant.coordinates.longitude),
                  brand_name: establishment?.merchant?.brand?.description, 
                  activity: establishment?.merchant?.brand?.description, 
                  distance: "",
                  distanceNumber: "",

                  show: true, // exibir estabelecimento no mapa de acordo com range

                  brandSelect: true, // informar se a bandeira está selecionada
                  showBrand: true, // exibir estabelecimento no mapa de acordo com a bandeiras e o range
                  showPrice: true, // exibir preço no modal impressão
                  showPriceBrand: true, // exibir preço no modal impressão de acordo com filtro de bandeiras
                  showAllBrands: true,

                  activitySelect: true, // informar se a atividade está selecionada
                  showActivity: true, // exibir estabelecimento no mapa de acordo com a atividade e o range
                  showPrice: true, // exibir preço no modal impressão
                  showPriceBrand: true, // exibir preço no modal impressão de acordo com filtro de bandeiras
                  showAllActivities: true,
                  selected: establishment?.route?.at(0)?.id ? true : false, // informar se o estabelecimento foi selecionado no modal de impressão (checkbox)
                  ignoreSelection: establishment?.route?.at(0)?.id ? false : true,
                  nome: establishment?.merchant?.tradeName,
                  endereço: establishment?.merchant?.addresses?.at(0)?.street + ', ' + establishment?.merchant?.addresses?.at(0)?.number, 
                  estado: establishment?.merchant?.addresses?.at(0)?.city + ' - ' + establishment?.merchant?.addresses?.at(0)?.state, 
                  phone: establishment?.merchant?.cellphone,
                  valores: formattedProducts,
                  preferential: establishment?.preferential
                });

                formattedList.push(obj);
              };

              var displayedFormatted = displayed.toString();
              var originCoordinate = await searchCoordinate(origin);

              var latLocation = parseFloat(originCoordinate.results[0].geometry.location.lat);
              var lngLocation = parseFloat(originCoordinate.results[0].geometry.location.lng);

              const { data: responseDisplayed } = await api.get(
                `distance?ids=${displayedFormatted}&origin=${latLocation + ',' + lngLocation}`
              );


              var formattedListDistance = formattedList.map((establishment, index) => {
                responseDisplayed.forEach((show) => {
                  if (establishment.id === show.id) {
                    formattedList[index].distance = show.text;
                    formattedList[index].distanceNumber = show.value;
                  }
                })
                return establishment
              })

              formattedListDistance.sort((a, b) => (a.distanceNumber > b.distanceNumber) ? 1 : -1)

              setListEstablishments(formattedListDistance);
              setShowEstablishments(true);
              setModalEstabelecimentos(true);
              setLoading(false);
            } catch (error) {
              console.log(error);
            }
          }

          loadRouterMarker();

        } else {
          console.log(status)
          if (status === window.google.maps.DirectionsStatus.NOT_FOUND) {
            toast.error("Rota não encontrada! Preencha todos endereços corretamente.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored"
            });
          }
          if (status === window.google.maps.DirectionsStatus.ZERO_RESULTS) {
            toast.error("Nenhuma rota foi encontrada para estes endereços.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "colored"
            });
          }
          setLoading(false);
        }
      }
    );
  }, [pontoPartida, endereços, balsa, rodovia, pedagio, rangeRota, rota, brands, filterPostos, filterServiços, filterFavoritos, onRangeNumber, services, setCollapsed, setDirectionsResponse, setListEstablishments, setModalEstabelecimentos]);

  const changeShowAllBrands = useCallback(async () => {
    setFilterPostos(!filterPostos);

    const formattedList = listEstablishments?.map((establishments) => {
      if ((establishments.activity === "POSTOS COMBUSTIVEIS" || establishments.activity === "ESTACION DE SERVICIOS" || establishments.activity === "GASOLINERA")) {
        establishments.showAllBrands = !filterPostos
      }
      return establishments
    })

    setListEstablishments(formattedList);
  }, [filterPostos, listEstablishments, setListEstablishments]);

  const changeShowAllActivities = useCallback(async () => {
    setFilterServiços(!filterServiços);

    const formattedList = listEstablishments?.map((establishments) => {
      if ((establishments.activity !== "POSTOS COMBUSTIVEIS" && establishments.activity !== "ESTACION DE SERVICIOS" && establishments.activity !== "GASOLINERA")) {
        establishments.showAllActivities = !filterServiços
      }
      return establishments
    })

    setListEstablishments(formattedList);
  }, [filterServiços, listEstablishments, setListEstablishments]);

  const changeShowAllFavorites = useCallback(async () => {
    setFilterFavoritos(!filterFavoritos);

    if (!filterFavoritos) {
      const formattedList = listEstablishments?.map((establishments) => {
        if (establishments.favorite) {
          establishments.showAllFavorites = !filterFavoritos
        } else {
          establishments.showAllFavorites = false
        }
        return establishments
      })
      setListEstablishments(formattedList);

    } else {
      const formattedList = listEstablishments?.map((establishments) => {
        establishments.showAllFavorites = true
        return establishments
      })
      setListEstablishments(formattedList);

    }
  }, [filterFavoritos, listEstablishments, setListEstablishments]);

  useEffect(() => {
    loadPosition();
    loadEstablishments();
    setCollapsed(false);
    setPontoPartida("")
    setEndereços([{
      local: "",
    }]);
    setDirectionsResponse(null)
    loadStates();
  }, []); // eslint-disable-line

  const handleCollapsed = (e) => {
    setCollapsed(false);
    setMenuOpen(e);
  }

  const handleClear = useCallback(() => {
    setRota("");
    setRangeRota(3);
    setPedagio(false);
    setBalsa(false);
    setRodovia(false);
    setPontoPartida("");
    setEndereços([{
      local: ""
    }]);
    setShowEstablishments(false);

    setEndereçoRaio("");
    setRangeRaio(3);
    setMarkerRaio([]);

    setEstabelecimento("");
    setEstadoEstabelecimento("");
    setMunicipioEstabelecimento("");

    setDirectionsResponse(null)
    setModalEstabelecimentos(false);
    loadPosition();
    loadEstablishments();

    var newBrands = brands?.map((brand) => {
      brand.show = false;
      return brand
    })
    setBrands(newBrands)

    var newServices = services?.map((service) => {
      service.show = false;
      return service
    })
    setServices(newServices)

  }, [setEndereços, setModalEstabelecimentos, setPontoPartida, setServices, brands, loadEstablishments, loadPosition, services, setBrands, setDirectionsResponse]);

  const handleAddEndereço = useCallback(() => {
    setEndereços([
      ...endereços,
      {
        local: "",
      },
    ]);

  }, [endereços, setEndereços]);

  const handleRemoveEndereço = useCallback((position) => {
    setEndereços([
      ...endereços.filter((_, index) => {
        return index !== position;
      }),
    ]);
  }, [endereços, setEndereços]);

  const handleChangeInputEndereço = useCallback(
    (e, index) => {
      endereços[index].local = e
      setEndereços([...endereços]);
    },
    [endereços, setEndereços]
  );

  // MAPA
  const [libraries] = useState(['places', 'geometry']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBLsefFujLlZVg2M2wqF3pnaQURKV8AGHY",
    libraries,
  });

  if (!isLoaded) {
    return <SkeletonText />
  }

  async function calculateRadius() {
    setCollapsed(true);
    setLoading(true);
    setShowEstablishments(false);
    setModalEstabelecimentos(false);
    setFilterFavoritos(false);
    setDirectionsResponse(null);
    setMenuSearch("raio");

    // LIMPAR TODOS DADOS
    setDirectionsResponse(null);
    var allEstablishments = [];
    const response = await searchCoordinate(endereçoRaio);

    const latLocation = parseFloat(response.results[0].geometry.location.lat);
    const lngLocation = parseFloat(response.results[0].geometry.location.lng)

    console.log('RAIO', this);
    var radiusFilter = (latLocation + "," + lngLocation).toString();

    try {
      // const { data: response } = await api.get(
      //     `merchants?columns=brand_icon,brand_name,street,number,state,city,company_name,trade_name,activity,phone,preferential&products=true&favorites=true&radius=${radiusFilter}&lat=${latLocation}&lon=${lngLocation}`
      // );

      const raio = 20;
      const queryParams = `&lat=${latLocation}&lng=${lngLocation}&radius=${raio}`;
      MerchantAPI.fetchAll(100, queryParams, (merchants) => {
        const formattedList = merchants.map((m) => {
          return setMerchantData(m);
        });

        allEstablishments = formattedList.map((m) => {
          let formattedProducts = m.products?.map((product) => {
            let obj2 = {};
            Object.assign(obj2, {
              id: product.id,
              nome: product.name,
              preço: product.price,
              data: product.date,
              show: false
            });
            return obj2
          });

          m.endereço = m.street + '; ' + m.number;
          m.estado = m.city + ' - ' + m.state;
          m.phone = m.phone;

          m.valores = formattedProducts;
          m.preferential = m.preferential;
          return m;
        });
      });
    } catch (error) {
      console.log(error)
    }
    ///////////////////

    try {


      setMarkerRaio({
        icon: Pluxee,
        lat: latLocation,
        lng: lngLocation,
        radius: onRangeNumber('circle')
      });
      setCenter({ lat: latLocation, lng: lngLocation });

      let formattedEstablishments = allEstablishments.map((establishment) => {
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween({ lat: establishment.lat, lng: establishment.lng }, { lat: latLocation, lng: lngLocation });

        var radius = false

        if (distance <= onRangeNumber('circle')) {
          radius = true;
        }

        var count = 0;
        var brandSelect = false;
        var showAllBrands = true

        if ((establishment.activity === "POSTOS COMBUSTIVEIS" || establishment.activity === "ESTACION DE SERVICIOS" || establishment.activity === "GASOLINERA")) {
          brands.forEach((brand) => {
            if ((brand.name === establishment.brand_name) && brand.show === true) {
              brandSelect = true;
            }
            if (brand.show === true) {
              count = count + 1

            }
          });

          showAllBrands = filterPostos
        }

        /////////////////////////////////////////////

        var countActivity = 0;
        var activitySelect = false;
        var showAllActivities = true

        if ((establishment.activity !== "POSTOS COMBUSTIVEIS" && establishment.activity !== "ESTACION DE SERVICIOS" && establishment.activity !== "GASOLINERA")) {
          services.forEach((service) => {
            if ((service.name === establishment.activity) && service.show === true) {
              activitySelect = true;
            }
            if (service.show === true) {
              countActivity = countActivity + 1
            }
          })

          showAllActivities = filterServiços
        }

        let obj = {};
        Object.assign(obj, {
          id: establishment.id,
          favorite: establishment.favorite,
          showAllFavorites: true,
          icon: establishment.icon,
          brand_name: establishment.brand_name,
          lat: establishment.lat,
          lng: establishment.lng,
          show: radius,

          brandSelect: count === 0 ? true : brandSelect,
          showBrand: radius,
          showPrice: false, // exibir preço no modal impressão
          showPriceBrand: true, // exibir preço no modal impressão de acordo com filtro de bandeiras
          showAllBrands: showAllBrands,

          activitySelect: countActivity === 0 ? true : activitySelect, // informar se a atividade está selecionada
          showActivity: radius, // exibir estabelecimento no mapa de acordo com a atividade e o range
          showAllActivities: showAllActivities,
          activity: establishment.activity,

          nome: establishment.nome,
          endereço: establishment.endereço,
          estado: establishment.estado,
          valores: establishment.valores,
          phone: establishment.phone,

          selected: false,

        });

        return obj
      });

      onHandleZoom();
      setListEstablishments(formattedEstablishments);
      setShowEstablishments(true);
      setModalEstabelecimentos(true);

    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }

  async function calculateNetwork() {
    setCollapsed(true);
    setLoading(true);
    setShowEstablishments(false);
    setModalEstabelecimentos(false);
    setMarkerRaio([]);
    setFilterFavoritos(false);
    setMenuSearch("estabelecimento");


    // LIMPAR TODOS DADOS
    setDirectionsResponse(null);
    var allEstablishments = [];
    try {
      var stateFilter = estadoEstabelecimento?.label ? estadoEstabelecimento?.label : "";
      var cityFilter = municipioEstabelecimento?.label ? municipioEstabelecimento?.label : "";

      const { data: response } = await api.get(`merchants?columns=brand_icon,brand_name,street,number,state,city,company_name,trade_name,activity,phone,preferential&products=true&favorites=true&state=${stateFilter}&city=${cityFilter}&trade_name=${estabelecimento}`);
      allEstablishments = response.data.map((establishment) => {

        var count = 0;
        var brandSelect = false;
        var showAllBrands = true

        if ((establishment.activity === "POSTOS COMBUSTIVEIS" || establishment.activity === "ESTACION DE SERVICIOS" || establishment.activity === "GASOLINERA")) {
          brands.forEach((brand) => {
            if ((brand.name === establishment.brand_name) && brand.show === true) {
              brandSelect = true;
            }
            if (brand.show === true) {
              count = count + 1

            }
          });
          showAllBrands = filterPostos
        }

        /////////////////////////////////////////////

        var countActivity = 0;
        var activitySelect = false;
        var showAllActivities = true

        if ((establishment.activity !== "POSTOS COMBUSTIVEIS" && establishment.activity !== "ESTACION DE SERVICIOS" && establishment.activity !== "GASOLINERA")) {
          services.forEach((service) => {
            if ((service.name === establishment.activity) && service.show === true) {
              activitySelect = true;
            }
            if (service.show === true) {
              countActivity = countActivity + 1
            }
          })

          showAllActivities = filterServiços
        }

        let formattedProducts = establishment.products?.map((product) => {
          let obj2 = {};
          Object.assign(obj2, {
            id: product.id,
            nome: product.name,
            preço: product.pivot.price,
            data: product.pivot.date,
            show: false
          });
          return obj2

        });

        const obj = {};
        Object.assign(obj, {
          id: establishment.id,
          favorite: establishment.user[0] ? true : false,
          showAllFavorites: true,
          icon: establishment.brand_icon,
          lat: parseFloat(establishment.latitude),
          lng: parseFloat(establishment.longitude),
          brand_name: establishment.brand_name,
          show: true,
          showBrand: true,
          brandSelect: count === 0 ? true : brandSelect,
          showAllBrands: showAllBrands,

          activitySelect: countActivity === 0 ? true : activitySelect, // informar se a atividade está selecionada
          showActivity: true, // exibir estabelecimento no mapa de acordo com a atividade e o range
          showAllActivities: showAllActivities,
          activity: establishment.activity,

          city: establishment.city,
          state: establishment.state,
          name: establishment.trade_name,

          selected: false,
          //////mudar
          nome: establishment.trade_name,
          endereço: establishment.street + ', ' + establishment.number,
          estado: establishment.city + ' - ' + establishment.state,
          phone: establishment.phone,

          valores: formattedProducts,
          preferential: establishment.preferential
        });

        return obj;
      });
    } catch (error) {
      console.log(error)
    }

    setListEstablishments(allEstablishments);
    setModalEstabelecimentos(true);
    setShowEstablishments(true);

    if (municipioEstabelecimento) {
      setCenter({ lat: municipioEstabelecimento.lat, lng: municipioEstabelecimento.lng })
      setZoom(12);
    } else {
      setCenter({ lat: estadoEstabelecimento.lat, lng: estadoEstabelecimento.lng })
      setZoom(estadoEstabelecimento.zoom);
    }

    setLoading(false);
  }


  function onHandleZoom() {
    if (rangeRaio === 0) return setZoom(16)
    if (rangeRaio === 1) return setZoom(15)
    if (rangeRaio === 2) return setZoom(13)
    if (rangeRaio === 3) return setZoom(13)
    if (rangeRaio === 4) return setZoom(12)
    if (rangeRaio === 5) return setZoom(12)
    if (rangeRaio === 6) return setZoom(11)
  }

  function handleChangeZoom(zoom) {
    setZoom(zoom)
  }

  return (
    <Flex position='fixed' flexDirection='column' alignItems='center' h='100vh' w='100vw'>
      <Header>
        <img src={Pluxee} alt="Pluxee" />

        <ContainerMenu>
          <main>
            <div>
              <h3 style={{ fontSize: 20 }}><FormattedMessage id="roteirizador" /></h3>
            </div>
            <div>
              <p><FormattedMessage id="favoritos" /></p>
            </div>
            <Toggle
              defaultChecked={filterFavoritos}
              uncheckedicon={<FaTimes />}
              checkedicon={<AiFillStar />}
              oncolor={'#FF7375'}
              offcolor={'#B5B2BC'}
              onChange={() => changeShowAllFavorites()}
            />

            <div>
              <p><FormattedMessage id="postos" /></p>
              {modalFilterPostos ? (
                <FiChevronUp
                  onClick={() => setModalFilterPostos(!modalFilterPostos)}
                />
              ) : (
                <FiChevronDown
                  onClick={() => {
                    setModalFilterPostos(!modalFilterPostos);
                    setModalFilterRoutes(false);
                    setModalFilterServiços(false);
                  }}
                />
              )}
            </div>
            <Toggle
              defaultChecked={filterPostos}
              onChange={() => changeShowAllBrands()}
              uncheckedicon={<FaTimes />}
              checkedicon={<img src={iconBomba} alt="iconBomba" />}
              oncolor={'#FF7375'}
              offcolor={'#B5B2BC'}
            />

            <div>
              <p><FormattedMessage id="atividades" /></p>
              {modalFilterServiços ? (
                <FiChevronUp
                  onClick={() => setModalFilterServiços(!modalFilterServiços)}
                />
              ) : (
                <FiChevronDown
                  onClick={() => {
                    setModalFilterServiços(!modalFilterServiços)
                    setModalFilterRoutes(false);
                    setModalFilterPostos(false);
                  }}
                />
              )}
            </div>
            <Toggle
              defaultChecked={filterServiços}
              onChange={() => changeShowAllActivities()}
              uncheckedicon={<FaTimes />}
              checkedicon={<img src={iconFerramentas} alt="iconFerramentas" />}
              oncolor={'#FF7375'}
              offcolor={'#B5B2BC'}
            />
          </main>

          <h1>
            <FormattedMessage id="rotasSalvas" />
            {modalFilterRoutes ? (
              <FiChevronUp
                onClick={() => setModalFilterRoutes(!modalFilterRoutes)}
              />
            ) : (
              <FiChevronDown
                onClick={() => {
                  setModalFilterRoutes(!modalFilterRoutes)
                  setModalFilterPostos(false);
                  setModalFilterServiços(false);
                }}
              />
            )}
          </h1>
        </ContainerMenu>

        <ContainerMenuSmall>
          <main>
            <div>
              <p>Fav</p>
              <div></div>
              <Toggle
                defaultChecked={filterFavoritos}
                uncheckedicon={<FaTimes />}
                checkedicon={<AiFillStar />}
                oncolor={'#FF7375'}
                offcolor={'#B5B2BC'}
                onChange={() => changeShowAllFavorites()}
              />
            </div>

            <div>
              <p><FormattedMessage id="postos" /></p>
              {modalFilterPostos ? (
                <FiChevronUp
                  onClick={() => setModalFilterPostos(!modalFilterPostos)}
                  style={{ marginLeft: -5 }}
                />
              ) : (
                <FiChevronDown
                  onClick={() => {
                    setModalFilterPostos(!modalFilterPostos);
                    setModalFilterRoutes(false);
                    setModalFilterServiços(false);
                  }}
                  style={{ marginLeft: -5 }}
                />
              )}
              <Toggle
                defaultChecked={filterPostos}
                onChange={() => changeShowAllBrands()}
                uncheckedicon={<FaTimes />}
                checkedicon={<RiGasStationFill />}
                oncolor={'#FF7375'}
                offcolor={'#B5B2BC'}
              />
            </div>


            <div>
              <p><FormattedMessage id="atividades" /></p>
              {modalFilterServiços ? (
                <FiChevronUp
                  onClick={() => setModalFilterServiços(!modalFilterServiços)}
                />
              ) : (
                <FiChevronDown
                  onClick={() => {
                    setModalFilterServiços(!modalFilterServiços)
                    setModalFilterRoutes(false);
                    setModalFilterPostos(false);
                  }}
                />
              )}
              <Toggle
                defaultChecked={filterServiços}
                onChange={() => changeShowAllActivities()}
                uncheckedicon={<FaTimes />}
                checkedicon={<AiOutlineTool />}
                oncolor={'#FF7375'}
                offcolor={'#B5B2BC'}
              />
            </div>
          </main>

          <nav>
            <h1><FormattedMessage id="rotasSalvas" />
              {modalFilterRoutes ? (
                <FiChevronUp
                  onClick={() => setModalFilterRoutes(!modalFilterRoutes)}
                />
              ) : (
                <FiChevronDown
                  onClick={() => {
                    setModalFilterRoutes(!modalFilterRoutes)
                    setModalFilterPostos(false);
                    setModalFilterServiços(false);
                  }}
                />
              )}
            </h1>
          </nav>

        </ContainerMenuSmall>

        <section>
          <Language />
          <User />
        </section>
      </Header>

      {streetView && (
        <ButtonIcon>
          <FaTimes onClick={() => setStreetView(false)} />
        </ButtonIcon>
      )}

      <ModalFilter functionCalculateRoute={calculateRoute} />
      <ModalFixLocation />

      {modalEstabelecimentos && (<ModalPrint search={[menuSearch, rota, rangeRota, pedagio, balsa, rodovia, pontoPartida, endereços, distanceKM]} />)}

      <SideBar collapsed={collapsed} print={showEstablishments}>
        <ProSidebar style={{ position: 'absolute' }} collapsed={collapsed}>
          <Menu iconShape="circle" onClick={() => handleCollapsed('rota')} data-for='rota' data-tip='rota'>
            <SubMenu title="Rota" icon={<img src={iconRota} alt="iconRota" />} open={menuOpen === 'rota' ? true : false} />
          </Menu>
          <Tooltip
            id={'rota'}
            text={<h3><FormattedMessage id="buscarRota" /></h3>}
            place={'bottom'}
          />

          <Menu iconShape="circle" onClick={() => handleCollapsed('raio')} data-for='raio' data-tip='raio'>
            <SubMenu title="Raio" icon={<img src={iconRaio} alt="iconRaio" />} open={menuOpen === 'raio' ? true : false} />
          </Menu>
          <Tooltip
            id={'raio'}
            text={<h3><FormattedMessage id="buscarRaio" /></h3>}
            place={'bottom'}
          />

          <Menu iconShape="circle" onClick={() => handleCollapsed('rede')} data-for='rede' data-tip='rede'>
            <SubMenu title="rede" icon={<img src={iconRede} alt="iconRede" />} open={menuOpen === 'rede' ? true : false} />
          </Menu>
          <Tooltip
            id={'rede'}
            text={<h3><FormattedMessage id="buscarRede" /></h3>}
            place={'bottom'}
          />

          <Menu iconShape="circle" onClick={() => showEstablishments ? setModalEstabelecimentos(true) : ""} data-for='estabelecimento' data-tip='estabelecimento'>
            {showEstablishments ? (
              <SubMenu icon={<img src={iconLista} alt="iconLista" />} />
            ) : (
              <SubMenu icon={<img src={iconListaDisable} alt="iconListaDisable" />} />
            )}

          </Menu>
          <Tooltip
            id={'estabelecimento'}
            text={<h3><FormattedMessage id="listaEstabelecimentos" /></h3>}
            place={'bottom'}
          />

          <Menu iconShape="circle" onClick={() => handleClear()} data-for='limpar' data-tip='limpar'>
            <SubMenu title="Limpar" icon={<img src={iconFiltro} alt="iconFiltro" />} />
          </Menu>
          <Tooltip
            id={'limpar'}
            text={<h3><FormattedMessage id="limparFiltros" /></h3>}
            place={'bottom'}
          />
        </ProSidebar>
      </SideBar>

      <SideBarIcons collapsed={collapsed} menu={menuOpen}>
        <ProSidebar style={{ position: 'absolute' }} collapsed={collapsed}>
          <Menu>
            <div style={{ textAlign: '-webkit-right' }}>
              {!collapsed && (
                <FaTimes
                  className='close'
                  onClick={() => {
                    setCollapsed(true);
                    setMenuOpen("rota");
                  }}
                />
              )}
            </div>

            <SubMenu open={true}>
              {menuOpen === "rota" && (
                <>
                  <h4>{<FormattedMessage id="rota" />}</h4>
                  <hr />
                  <RadioButton>
                    <p><FormattedMessage id="tipoRede" />:</p>
                    <section>
                      <div>
                        <InputText
                          type="radio"
                          name="select"
                          id="option-1"
                          onChange={() => setRota("Urbana")}
                          checked={rota === "Urbana" ? true : false}
                        />
                        <InputText
                          type="radio"
                          name="select"
                          id="option-2"
                          onChange={() => setRota("Rodoviaria")}
                          checked={rota === "Rodoviaria" ? true : false}
                        />

                        <label htmlFor="option-1" className="option-1">
                          <AiOutlineCar />
                          <span>{<FormattedMessage id="urbana" />}</span>
                        </label>
                        <label htmlFor="option-2" className="option-2">
                          <BiBusSchool />
                          <span>{<FormattedMessage id="rodoviaria" />}</span>
                        </label>
                      </div>
                    </section>
                  </RadioButton>



                  <p style={{ color: '#545454' }}><FormattedMessage id="raio" />:</p>
                  <SliderRange
                    value={rangeRota}
                    labelmin={'100m'}
                    labelmax={'5km'}
                    type={"rota"}
                    onChange={v => {
                      setRangeRota(v)
                    }}
                  />

                  <hr />

                  <Checkbox grid={"evitar"}>
                    <div>
                      <label>{<FormattedMessage id="evitarPedagio" />}
                        <InputText
                          type="checkbox"
                          onChange={() => setPedagio(!pedagio)}
                          checked={pedagio ? true : false}
                        />
                        <span></span>
                      </label>

                      <label>{<FormattedMessage id="evitarBalsa" />}
                        <InputText
                          type="checkbox"
                          onChange={() => setBalsa(!balsa)}
                          checked={balsa ? true : false}
                        />
                        <span></span>
                      </label>

                      <label>{<FormattedMessage id="evitarRodovia" />}
                        <InputText
                          type="checkbox"
                          onChange={() => setRodovia(!rodovia)}
                          checked={rodovia ? true : false}
                        />
                        <span></span>
                      </label>
                    </div>
                  </Checkbox>

                  <hr />

                  <ContainerIndex>
                    <FiMapPin />
                    <Autocomplete>
                      <InputText
                        value={pontoPartida}
                        onChange={(e) => setPontoPartida(e.target.value)}
                        onBlur={(e) => setPontoPartida(e.target.value)}
                        placeholder={startPlaceholder}
                        type="text"
                      />
                    </Autocomplete>

                  </ContainerIndex>

                  {endereços.map((endereço, index) => (
                    <ContainerIndex key={index}>
                      <FiMapPin />
                      <Autocomplete>
                        <InputText
                          value={endereço.local}
                          onChange={(e) => handleChangeInputEndereço(e.target.value, index)}
                          onBlur={(e) => handleChangeInputEndereço(e.target.value, index)}
                          placeholder={destinationPlaceholder}
                          type="text"
                        />
                      </Autocomplete>

                      {endereços.length - 1 === index ? (
                        <>
                          {endereços.length < 9 ? (
                            <AiOutlinePlusCircle
                              onClick={() => handleAddEndereço()}
                              style={{ color: "#FF7375", width: 28, height: 28, cursor: 'pointer', border: 50 }}
                            />
                          ) : (
                            <FiTrash2 onClick={() => handleRemoveEndereço(index)} />
                          )}

                        </>
                      ) : (
                        <FiTrash2 onClick={() => handleRemoveEndereço(index)} />
                      )}

                    </ContainerIndex>
                  ))}

                  <Button disabled={disableRouterSearchButton} label={<FormattedMessage id="buscar" />} onClick={calculateRoute} />

                </>
              )}

              {menuOpen === "raio" && (
                <>
                  <h4>{<FormattedMessage id="raio" />}</h4>
                  <hr />
                  <ContainerIndex style={{ gridTemplateColumns: '10% 90%' }}>
                    <FiMapPin />
                    <Autocomplete>
                      <InputText
                        value={endereçoRaio}
                        onChange={(e) => setEndereçoRaio(e.target.value)}
                        onBlur={(e) => setEndereçoRaio(e.target.value)}
                        placeholder={addressPlaceholder}
                        type="text"
                      />
                    </Autocomplete>
                  </ContainerIndex>

                  <p style={{ color: '#545454' }}><FormattedMessage id="raio" />:</p>
                  <SliderRange
                    value={rangeRaio}
                    labelmin={'500m'}
                    labelmax={'15km'}
                    type={"raio"}
                    onChange={v => {
                      setRangeRaio(v)
                    }}
                  />

                  <Button disabled={disableRadiusSearchButton} label={<FormattedMessage id="buscar" />} onClick={calculateRadius} />
                </>
              )}

              {menuOpen === "rede" && (
                <>
                  <h4>{<FormattedMessage id="rede" />}</h4>
                  <hr />
                  <DropDown
                    options={optionsEstado}
                    onChange={(e) => {
                      setEstadoEstabelecimento(e);
                      setMunicipioEstabelecimento("")
                      if (e?.value) {
                        loadCities(e.value);
                      }
                    }}
                    value={estadoEstabelecimento}
                    placeholder={statePlaceholder}
                  />

                  <DropDown
                    options={optionsMunicipio}
                    onChange={(e) => {
                      setMunicipioEstabelecimento(e);
                    }}
                    value={municipioEstabelecimento}
                    placeholder={cityPlaceholder}
                    isDisabled={estadoEstabelecimento?.value ? false : true}
                  />

                  <InputText
                    onChange={(e) => {
                      setEstabelecimento(e.target.value)
                    }}
                    value={estabelecimento}
                    placeholder={establishmentPlaceholder}
                  />

                  <Button disabled={disableCitySearchButton} label={<FormattedMessage id="buscar" />} onClick={() => calculateNetwork()} />
                </>
              )}

            </SubMenu>
          </Menu>
        </ProSidebar>

      </SideBarIcons>

      {loading && (
        <LoaderContent />
      )}

      <MapContainer>
        <GoogleMap
          center={center}
          zoom={zoom}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            // fullscreenControl: false,
          }}
          onZoomChanged={() => {
            if (map?.getZoom()) {
              handleChangeZoom(map?.getZoom());
            }
          }}
          onLoad={map => setMap(map)}
        >
          <>
            <StreetViewPanorama
              visible={streetView}
              onPanoChanged={() => setStreetView(true)}
            />
            {markerRaio.radius && (
              <>
                <Circle
                  center={{
                    lat: markerRaio?.lat,
                    lng: markerRaio?.lng
                  }}
                  radius={markerRaio?.radius}
                  options={{ strokeColor: "#931a28" }}
                />
                <Marker
                  position={{ lat: markerRaio?.lat, lng: markerRaio?.lng }}
                  icon={{
                    url: "https://cdn-icons-png.flaticon.com/512/999/999105.png",
                    scaledSize: new window.google.maps.Size(30, 30)
                  }} />
              </>
            )}
            {listEstablishments?.map((establishment, index) => {
              return (
                // (establishment.show && establishment.brandSelect && establishment.showAllBrands && establishment.activitySelect && establishment.showAllActivities && establishment.showAllFavorites) && (
                // zoom <= 8 && establishment.preferential === 1 ? (
                //   <Marker
                //     key={index}
                //     position={{ lat: establishment.lat, lng: establishment.lng }}
                //     icon={{
                //       url: establishment.icon,
                //       scaledSize: new window.google.maps.Size(23, 23)
                //     }}
                //     onClick={() => {
                //       setOpenModal(true);
                //       setIndexModal(index)
                //     }}
                //   >
                //     {openModal && indexModal === index &&
                //       <InfoWindow
                //         onCloseClick={() => {
                //           setOpenModal(false)
                //           setIndexModal(null)
                //         }}
                //       >
                //         <ModalInfo id={establishment.id} center={center} />
                //       </InfoWindow>
                //     }
                //   </Marker>
                // ) : (
                  // zoom > 8 ? (
                    <Marker
                      key={index}
                      position={{ lat: establishment.lat, lng: establishment.lng }}
                      icon={{
                        url: establishment.icon != "nobrand.png" ? establishment.icon : nobrandIcon,
                        scaledSize: new window.google.maps.Size(23, 23)
                      }}
                      onClick={() => {
                        setOpenModal(true);
                        setIndexModal(index)
                      }}
                    >
                      {openModal && indexModal === index &&
                        <InfoWindow
                          onCloseClick={() => {
                            setOpenModal(false)
                            setIndexModal(null)
                          }}
                        >
                          <ModalInfo id={establishment?.id} center={center} />
                        </InfoWindow>
                      }
                    </Marker>
                  // ) : (
                  //   ""
                  // )
                // )
                // )
              )
            })}
          </>
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </MapContainer>
      <CarouselImage />
    </Flex>
  )
}

export default memo(injectIntl(App))
