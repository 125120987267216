import React, { useEffect, useCallback }  from "react";
import { useGlobal, setGlobal } from 'reactn';
import { AiOutlineTool } from 'react-icons/ai'
import { FaTimes, FaRoute } from 'react-icons/fa'
import { RiGasStationFill } from 'react-icons/ri'
import { FiTrash2 } from 'react-icons/fi' 
import InputText from '../InputDefault';
import api from "./../../services/api";
import ReactModal from 'react-modal-resizable-draggable';
import { FormattedMessage, injectIntl} from "react-intl";
import { Container, Checkbox, Modal } from "./styles";
import Tooltip from "../Tooltip";
import iconBomba from "./../../assets/bomba-branco.png";
import iconFerramentasBranco from "./../../assets/ferramentas-branco.svg";
import iconFerramentas from "./../../assets/ferramentas.svg";
import iconRota from "./../../assets/rota.png";
import iconRotasSalvas from "./../../assets/rotas-salvas.png";
import { Button } from '@dilicorp/ui';
 
setGlobal({});

const ModalFilter = ({functionCalculateRoute, intl}) => {
  
  const Swal = require('sweetalert2')
  
  const [listEstablishments, setListEstablishments] = useGlobal('listEstablishment');
  const [routes, setRoutes] = useGlobal('savedRoutes');
  
  //MODAL
  const [modalFilterPostos, setModalFilterPostos] = useGlobal('modalFilterPostos');
  const [modalFilterServiços, setModalFilterServiços] = useGlobal('modalFilterServiços');
  const [modalFilterRoutes, setModalFilterRoutes] = useGlobal('modalFilterRoutes');
  
  //LIST
  const [services, setServices] = useGlobal('services');
  const [brands, setBrands] = useGlobal('brands');
  
  const deleteRoutePlaceholder = intl.formatMessage({ id: "apagarRota" });
  const simPlaceholder = intl.formatMessage({ id: "sim" });
  const naoPlaceholder = intl.formatMessage({ id: "não" });
  
  const loadServices = useCallback(async () => {
    try {
      
      const { data: response } = await api.get(`activities`);
      
      const formattedServices = response.data.map((service) => {
        const obj = {};

        Object.assign(obj, {
          id: service.id,
          icon: AiOutlineTool,
          name: service.name,
          show: false
        });

        return obj;
      });

      setServices(formattedServices)
    } catch (error) {
      console.log(error)
    }
  }, [setServices]);
  
  const loadBrands = useCallback(async () => {
    try {
      
      const { data: response } = await api.get(`brands`);
      
      const formattedBrands = response.data.map((brands) => {
        const obj = {};

        Object.assign(obj, {
          id: brands.id,
          icon: brands.icon,
          name: brands.name,
          show: false
        });

        return obj;
      });

      setBrands(formattedBrands)
    } catch (error) {
      console.log(error)
    }
  }, [setBrands]);
  
  const loadRoutes = useCallback(async () => {
    try {
      
      const { data: response } = await api.get(`routes`);
      
      const formattedRoutes = response.data.map((route) => {
        const obj = {};
        Object.assign(obj, {
            name: route.alias,
            id: route.id,
            initial: route.address_1,
            adresses: [
              route.address_2 && route.address_2,
              route.address_3 && route.address_3,
              route.address_4 && route.address_4,
              route.address_5 && route.address_5,
              route.address_6 && route.address_6,
              route.address_7 && route.address_7,
              route.address_8 && route.address_8,
              route.address_9 && route.address_9,
              route.address_10 && route.address_10,
            ],
            typeRoute: route.type ? route.type : "",
            radius: route.radius,
            tolls: route.tolls === "0" ? false : true,
            ferries: route.ferries === "0" ? false : true,
            highways: route.highways === "0" ? false : true,
        });

        return obj;
      });

      setRoutes(formattedRoutes)
    } catch (error) {
      console.log(error)
    }
  }, [setRoutes]);
  
  const calculateRoute = useCallback(async (route) => {
    functionCalculateRoute(route);
    setModalFilterRoutes(false);
    loadRoutes();
  }, [functionCalculateRoute, loadRoutes, setModalFilterRoutes]);
  
  const handleDelete = useCallback(async (route) => {
    
    Swal.fire({
      title: deleteRoutePlaceholder,
      showDenyButton: true,
      confirmButtonText: simPlaceholder,
      denyButtonText: naoPlaceholder,
    }).then(async(result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`routes?id=${route.id}`);
          loadRoutes();
        } catch (error) {
          console.log(error)
        }
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
    

  }, [Swal, deleteRoutePlaceholder, loadRoutes, naoPlaceholder, simPlaceholder]);
  
  useEffect(() => {
    loadServices();
    loadBrands();
    loadRoutes();
}, [loadBrands, loadRoutes, loadServices]);

const changeShowBrands = useCallback(async (brand, index) => {
  
  brands[index].show = !brands[index].show
  setBrands([...brands]);
  
  var count = 0;
  var onlyBrand = "";
  
  brands.forEach((brand) => {
    if (brand.show){
      count = count + 1;
      onlyBrand = brand.name
    }
  })
  
  const formattedList = listEstablishments?.map((establishments) => {
    // SE COUNT = 0 TODAS BANDEIRAS SÃO EXIBIDAS
    if (count === 0 && (establishments.activity === "POSTOS COMBUSTIVEIS" || establishments.activity === "ESTACION DE SERVICIOS" || establishments.activity === "GASOLINERA")){
      if (establishments.showBrand) {
          establishments.show = true;
          establishments.brandSelect = true;
          
          if (establishments.showPriceBrand){
            establishments.showPrice = true;
          }
      }
    }
    
    // SE COUNT = 1 SOMENTE A BANDEIRA SELECIONADA SERÁ EXIBIDA
    if (count === 1 && (establishments.activity === "POSTOS COMBUSTIVEIS" || establishments.activity === "ESTACION DE SERVICIOS" || establishments.activity === "GASOLINERA")){
      establishments.show = false;
      establishments.brandSelect = false;
      establishments.showPrice = false;
      
      //TRUE
      if (establishments.showBrand) {
    
        if (onlyBrand === establishments.brand_name){
          establishments.show = true;
          establishments.brandSelect = true;
          
          if (establishments.showPriceBrand){
            establishments.showPrice = true;
          }
        }
      }
    }
      
    // SE COUNT > 1 TODAS BANDEIRAS SELECIONADAS SÃO EXIBIDAS
    if (count > 1 && (establishments.activity === "POSTOS COMBUSTIVEIS" || establishments.activity === "ESTACION DE SERVICIOS" || establishments.activity === "GASOLINERA")) {
    //TRUE
    if (brands[index].show && establishments.showBrand) {
      if (brand.name === establishments.brand_name){
        establishments.show = true;
        establishments.brandSelect = true;
        
        if (establishments.showPriceBrand){
          establishments.showPrice = true;
        }
      }
    }
  
    //FALSE
    if (!brands[index].show && establishments.showBrand) {
      if (brand.name === establishments.brand_name){
        establishments.show = false;
        establishments.brandSelect = false;
        
        if (establishments.showPriceBrand){
          establishments.showPrice = false;
        }
      }
    }
  }
  
    return establishments
  })
  
  setListEstablishments(formattedList);
}, [listEstablishments, brands, setBrands, setListEstablishments]);

const clearAllBrands = useCallback(async () => {
  try {
      
    const { data: response } = await api.get(`brands`);
    
    const formattedBrands = response.data.map((brands) => {
      const obj = {};

      Object.assign(obj, {
        id: brands.id,
        icon: brands.icon,
        name: brands.name,
        show: false
      });

      return obj;
    });

    setBrands(formattedBrands)
  } catch (error) {
    console.log(error)
  }
 
  const formattedList = listEstablishments?.map((establishments) => {
    if ((establishments.activity === "POSTOS COMBUSTIVEIS" || establishments.activity === "ESTACION DE SERVICIOS" || establishments.activity === "GASOLINERA")){
      if (establishments.showBrand) {
          establishments.show = true;
          establishments.brandSelect = true;
          
          if (establishments.showPriceBrand){
            establishments.showPrice = true;
          }
      }
    }
    return establishments
  })
  
  setListEstablishments(formattedList);
}, [listEstablishments, setBrands, setListEstablishments]);

const changeShowActivities = useCallback(async (service, index) => {

  services[index].show = !services[index].show
  setServices([...services]);
  
  var count = 0;
  var onlyService = "";
  
  services.forEach((service) => {
    if (service.show){
      count = count + 1;
      onlyService = service.name
    }
  })
  
  
  const formattedList = listEstablishments?.map((establishments) => {
    // SE COUNT = 0 TODAS BANDEIRAS SÃO EXIBIDAS
    if (count === 0 && (establishments.activity !== "POSTOS COMBUSTIVEIS" && establishments.activity !== "ESTACION DE SERVICIOS" && establishments.activity !== "GASOLINERA")){
      if (establishments.showActivity) {
          establishments.show = true;
          establishments.activitySelect = true;
          
          // if (establishments.showPriceBrand){
          //   establishments.showPrice = true;
          // }
      }
    }
    
    // SE COUNT = 1 SOMENTE A BANDEIRA SELECIONADA SERÁ EXIBIDA
    if (count === 1 && (establishments.activity !== "POSTOS COMBUSTIVEIS" && establishments.activity !== "ESTACION DE SERVICIOS" && establishments.activity !== "GASOLINERA")){
      establishments.show = false;
      establishments.activitySelect = false;
      // establishments.showPrice = false;
      
      //TRUE
      if (establishments.showActivity) {
    
        if (onlyService === establishments.activity){
          establishments.show = true;
          establishments.activitySelect = true;
          
          // if (establishments.showPriceBrand){
          //   establishments.showPrice = true;
          // }
        }
      }
    }
      
    // SE COUNT > 1 TODAS BANDEIRAS SELECIONADAS SÃO EXIBIDAS
    if (count > 1 && (establishments.activity !== "POSTOS COMBUSTIVEIS" && establishments.activity !== "ESTACION DE SERVICIOS" && establishments.activity !== "GASOLINERA")) {
    //TRUE
    if (services[index].show && establishments.showActivity) {
      if (service.name === establishments.activity){
        establishments.show = true;
        establishments.activitySelect = true;
        
        // if (establishments.showPriceBrand){
        //   establishments.showPrice = true;
        // }
      }
    }
  
    //FALSE
    if (!services[index].show && establishments.showActivity) {
      if (service?.name === establishments.activity){
        establishments.show = false;
        establishments.activitySelect = false;
        
        // if (establishments.showPriceBrand){
        //   establishments.showPrice = false;
        // }
      }
    }
  }
  
    return establishments
  })
  
  setListEstablishments(formattedList);
}, [listEstablishments, services, setListEstablishments, setServices]);

const clearAllServices = useCallback(async () => {
  try {
    const { data: response } = await api.get(`activities`);
    
    const formattedServices = response.data.map((service) => {
      const obj = {};

      Object.assign(obj, {
        id: service.id,
        icon: AiOutlineTool,
        name: service.name,
        show: false
      });

      return obj;
    });

    setServices(formattedServices);
  } catch (error) {
    console.log(error)
  }
 
  const formattedList = listEstablishments?.map((establishments) => {
    if ((establishments.activity !== "POSTOS COMBUSTIVEIS" && establishments.activity !== "ESTACION DE SERVICIOS" && establishments.activity !== "GASOLINERA")){
      if (establishments.showActivity) {
        establishments.show = true;
        establishments.activitySelect = true;
      }
    }
    return establishments
  })
  
  setListEstablishments(formattedList);
}, [listEstablishments, setListEstablishments, setServices]);

  
  return (
    <>
      {modalFilterPostos && (
        <Modal>
          <ReactModal
           isOpen={() => setModalFilterPostos(true)}
           disableResize={true}
           top={60}
           left={'auto'}
          >
           <Container color={"#221C46"}>
             <header>
              <img src={iconBomba} alt="iconBomba" />
              
              <p><FormattedMessage id="filtroPostos" /></p> 
              <FaTimes onClick={() => setModalFilterPostos(false)} style={{cursor: 'pointer'}}/>
             </header>
             
             <nav>
                <h1>
                  <FormattedMessage id="bandeiras" />
                </h1>
                
                {brands.map((brand, index) => (
                  <Checkbox key={index}>
                    <main>
                      <img src={brand.icon} alt="brands"/>
                      
                      <p>{brand.name}</p>
                      <label>
                        <InputText 
                          type="checkbox"   
                          onChange={() => {
                            changeShowBrands(brand, index);
                          }}
                          checked={brand.show === true ? true : false}
                        />
                        <span></span>
                      </label>
                    </main>
                  </Checkbox>
                ))}
              </nav>
              <Button onClick={() => clearAllBrands()}><FormattedMessage id="limpar" /></Button>
           </Container>
         </ReactModal>
       </Modal>
      )}
      
      {modalFilterServiços && (
        <Modal>
          <ReactModal
           isOpen={() => setModalFilterPostos(true)}
           disableResize={true}
           top={60}
           left={'auto'}
          >
            <Container color={"#221C46"}>
             <header>
              {/* <AiOutlineTool/>  */}
              <img src={iconFerramentasBranco} alt="iconFerramentasBranco" />
              <p><FormattedMessage id="filtroAtividades" /></p> 
              <FaTimes onClick={() => setModalFilterServiços(false)} style={{cursor: 'pointer' }}/>
             </header>
                  
             <nav>
                <h1>
                  <FormattedMessage id="atividades" />
                </h1>
                
                {services.map((service, index) => (
                  <Checkbox key={index}>
                    <main>
                      <img src={iconFerramentas} alt="iconFerramentas" />
                      <p>{service.name}</p>
                      <label>
                        <InputText 
                          type="checkbox"   
                          onChange={() => {
                            changeShowActivities(service, index);
                          }}
                          checked={service.show === true ? true : false}
                        />
                        <span></span>
                      </label>
                    </main>
                  </Checkbox>
                ))}
              </nav>
              <Button onClick={() => clearAllServices()}><FormattedMessage id="limpar" /></Button>
           </Container>
         </ReactModal>
       </Modal>
      )}
      
      {modalFilterRoutes && (
        <Modal route={true}>
          <ReactModal
           isOpen={() => setModalFilterRoutes(true)}
           disableResize={true}
           top={60}
           left={'auto'}
          >
            <Container color={"#221C46"}>
             <header>
              {/* <FaRoute/> */}
              <img src={iconRota} alt="iconRota" /> 
              <p><FormattedMessage id="rotasSalvas" /></p> 
              <FaTimes onClick={() => setModalFilterRoutes(false)} style={{cursor: 'pointer' }}/>
             </header>
                  
             <nav>
                {routes.map((route, index) => (
                  <div key={index}>
                    <Checkbox route={true}>
                      <main>
                        <img src={iconRotasSalvas} alt="iconRotasSalvas" data-for={`address${index}`} data-tip={`address${index}`}/>
                        {/* <FaRoute data-for={`address${index}`} data-tip={`address${index}`}/>  */}
                  
                        <p>{route.name}</p>
                        <Button className="button-route" onClick={() => calculateRoute(route)}>
                          <FormattedMessage id="buscarRota"/>
                        </Button>
                          <FiTrash2 onClick={() => handleDelete(route)} style={{cursor: 'pointer'}}/> 
                      </main>
                    </Checkbox>
                    
                    <Tooltip
                      id={`address${index}`}
                      color={'#221C46'}
                      place={'bottom'} 
                      route={true}
                      text={
                        <div>
                          <h2><FormattedMessage id="listaEndereços" /></h2>
                          <main >
                            <span>{route.initial}</span>
                          </main>
                          {route.adresses?.map((address, indexAddress) => (
                            address && (
                              <main key={indexAddress} >
                                <span>{address}</span>
                              </main>
                            )
                          ))}
                        </div>
                      }
                    />
                  </div>
                ))}
              </nav>
           </Container>
         </ReactModal>
        
       </Modal>
      )}
    </>
  );
};

export default injectIntl(ModalFilter);
